/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { DropdownModelResultListingModel } from '../models/dropdown-model-result-listing-model';
import { MakeModel } from '../models/make-model';
import { MakeQueryModel } from '../models/make-query-model';
import { MakeSummaryModelResultListingModel } from '../models/make-summary-model-result-listing-model';

@Injectable({ providedIn: 'root' })
export class MakesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchMakes()` */
  static readonly SearchMakesPath = '/Makes/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchMakes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchMakes$Plain$Response(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.SearchMakesPath, 'get');
    if (params) {
      rb.query('key', params.key, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchMakes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchMakes$Plain(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.searchMakes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchMakes$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchMakes$Json$Response(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.SearchMakesPath, 'get');
    if (params) {
      rb.query('key', params.key, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchMakes$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchMakes$Json(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.searchMakes$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getMakesDropdown()` */
  static readonly GetMakesDropdownPath = '/Makes/list-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMakesDropdown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMakesDropdown$Plain$Response(
    params?: {
      isTruck?: boolean;
      isActive?: boolean;
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.GetMakesDropdownPath, 'get');
    if (params) {
      rb.query('isTruck', params.isTruck, {});
      rb.query('isActive', params.isActive, {});
      rb.query('estimateId', params.estimateId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMakesDropdown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMakesDropdown$Plain(
    params?: {
      isTruck?: boolean;
      isActive?: boolean;
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getMakesDropdown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMakesDropdown$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMakesDropdown$Json$Response(
    params?: {
      isTruck?: boolean;
      isActive?: boolean;
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.GetMakesDropdownPath, 'get');
    if (params) {
      rb.query('isTruck', params.isTruck, {});
      rb.query('isActive', params.isActive, {});
      rb.query('estimateId', params.estimateId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMakesDropdown$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMakesDropdown$Json(
    params?: {
      isTruck?: boolean;
      isActive?: boolean;
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getMakesDropdown$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getMakes()` */
  static readonly GetMakesPath = '/Makes/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMakes$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMakes$Plain$Response(
    params?: {
      body?: MakeQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MakeSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.GetMakesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MakeSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMakes$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMakes$Plain(
    params?: {
      body?: MakeQueryModel
    },
    context?: HttpContext
  ): Observable<MakeSummaryModelResultListingModel> {
    return this.getMakes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MakeSummaryModelResultListingModel>): MakeSummaryModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMakes$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMakes$Json$Response(
    params?: {
      body?: MakeQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MakeSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.GetMakesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MakeSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMakes$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMakes$Json(
    params?: {
      body?: MakeQueryModel
    },
    context?: HttpContext
  ): Observable<MakeSummaryModelResultListingModel> {
    return this.getMakes$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MakeSummaryModelResultListingModel>): MakeSummaryModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getMake()` */
  static readonly GetMakePath = '/Makes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMake$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMake$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MakeModel>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.GetMakePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MakeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMake$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMake$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MakeModel> {
    return this.getMake$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MakeModel>): MakeModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMake$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMake$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MakeModel>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.GetMakePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MakeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMake$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMake$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MakeModel> {
    return this.getMake$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MakeModel>): MakeModel => r.body)
    );
  }

  /** Path part for operation `updateMake()` */
  static readonly UpdateMakePath = '/Makes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMake$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMake$Plain$Response(
    params?: {
      id?: string;
      body?: MakeModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.UpdateMakePath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMake$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMake$Plain(
    params?: {
      id?: string;
      body?: MakeModel
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.updateMake$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMake$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMake$Json$Response(
    params?: {
      id?: string;
      body?: MakeModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.UpdateMakePath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMake$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMake$Json(
    params?: {
      id?: string;
      body?: MakeModel
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.updateMake$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `insertMake()` */
  static readonly InsertMakePath = '/Makes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertMake$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertMake$Plain$Response(
    params?: {
      body?: MakeModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.InsertMakePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insertMake$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertMake$Plain(
    params?: {
      body?: MakeModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.insertMake$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertMake$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertMake$Json$Response(
    params?: {
      body?: MakeModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.InsertMakePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insertMake$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertMake$Json(
    params?: {
      body?: MakeModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.insertMake$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `deleteMake()` */
  static readonly DeleteMakePath = '/Makes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMake$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMake$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MakeModel>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.DeleteMakePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MakeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMake$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMake$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MakeModel> {
    return this.deleteMake$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MakeModel>): MakeModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMake$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMake$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MakeModel>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.DeleteMakePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MakeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMake$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMake$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MakeModel> {
    return this.deleteMake$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MakeModel>): MakeModel => r.body)
    );
  }

  /** Path part for operation `isMakeInUse()` */
  static readonly IsMakeInUsePath = '/Makes/inuse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isMakeInUse$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  isMakeInUse$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.IsMakeInUsePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isMakeInUse$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isMakeInUse$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.isMakeInUse$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isMakeInUse$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  isMakeInUse$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.IsMakeInUsePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isMakeInUse$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isMakeInUse$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.isMakeInUse$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getNewMake()` */
  static readonly GetNewMakePath = '/Makes/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewMake$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewMake$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MakeModel>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.GetNewMakePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MakeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewMake$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewMake$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<MakeModel> {
    return this.getNewMake$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MakeModel>): MakeModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewMake$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewMake$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MakeModel>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.GetNewMakePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MakeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewMake$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewMake$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<MakeModel> {
    return this.getNewMake$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MakeModel>): MakeModel => r.body)
    );
  }

  /** Path part for operation `reassignMake()` */
  static readonly ReassignMakePath = '/Makes/reassign-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reassignMake()` instead.
   *
   * This method doesn't expect any request body.
   */
  reassignMake$Response(
    params?: {
      originId?: string;
      newId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MakesService.ReassignMakePath, 'put');
    if (params) {
      rb.query('originId', params.originId, {});
      rb.query('newId', params.newId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reassignMake$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reassignMake(
    params?: {
      originId?: string;
      newId?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.reassignMake$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
