import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { UsersService } from 'api';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-cc-reset',
  templateUrl: './cc-reset.component.html',
  styleUrls: ['./cc-reset.component.scss']
})
export class CcResetComponent implements OnInit {

  processing: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UsersService
  ) {}

  ngOnInit(): void {
    // Start processing
    this.processing = true;

    this.authService.isAuthenticated$.pipe(
      take(1), // Only take the first emitted value
      filter(isAuthenticated => isAuthenticated), // Only proceed if authenticated
      switchMap(() => 
        this.userService.clearCostCentre().pipe(
          catchError(err => {
            // Handle error from clearing cost centre
            alert("Change Cost Center Error!");
            window.location.href = environment.auth.ssoRoot;
            return of(null); // Return a fallback observable to complete the stream
          })
        )
      )
    ).subscribe({
      next: () => {
        this.processing = false;
        this.router.navigateByUrl("/auth");
      },
      error: err => {
        // Authentication error handling
        this.processing = false;
        alert('Authentication error!');
        window.location.href = environment.auth.ssoRoot;
      }
    });
  }
}
