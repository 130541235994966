/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AttachmentsService } from './services/attachments.service';
import { AutoEmailService } from './services/auto-email.service';
import { AutolineService } from './services/autoline.service';
import { BodyNetService } from './services/body-net.service';
import { BodyShapesService } from './services/body-shapes.service';
import { CaptureService } from './services/capture.service';
import { CheckInFormsService } from './services/check-in-forms.service';
import { CompaniesService } from './services/companies.service';
import { CompanyEmailConfigService } from './services/company-email-config.service';
import { CompanyOptionsService } from './services/company-options.service';
import { DashboardService } from './services/dashboard.service';
import { DerivativesService } from './services/derivatives.service';
import { EmailService } from './services/email.service';
import { EmailTemplatesService } from './services/email-templates.service';
import { EstimateEnquiryService } from './services/estimate-enquiry.service';
import { EstimatesService } from './services/estimates.service';
import { EstimateStatusService } from './services/estimate-status.service';
import { FinancingService } from './services/financing.service';
import { FormsService } from './services/forms.service';
import { GraphicsService } from './services/graphics.service';
import { InvoicesService } from './services/invoices.service';
import { MakesService } from './services/makes.service';
import { MediaViewService } from './services/media-view.service';
import { MenuTemplatesService } from './services/menu-templates.service';
import { ModelsService } from './services/models.service';
import { PaintTypesService } from './services/paint-types.service';
import { PanelsService } from './services/panels.service';
import { QuotesService } from './services/quotes.service';
import { SetupsService } from './services/setups.service';
import { SpecialistChargesService } from './services/specialist-charges.service';
import { TimeRecordingsService } from './services/time-recordings.service';
import { UsersService } from './services/users.service';
import { VdaService } from './services/vda.service';
import { VehiclesService } from './services/vehicles.service';
import { VehicleSizesService } from './services/vehicle-sizes.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AttachmentsService,
    AutoEmailService,
    AutolineService,
    BodyNetService,
    BodyShapesService,
    CaptureService,
    CheckInFormsService,
    CompaniesService,
    CompanyEmailConfigService,
    CompanyOptionsService,
    DashboardService,
    DerivativesService,
    EmailService,
    EmailTemplatesService,
    EstimateEnquiryService,
    EstimatesService,
    EstimateStatusService,
    FinancingService,
    FormsService,
    GraphicsService,
    InvoicesService,
    MakesService,
    MediaViewService,
    MenuTemplatesService,
    ModelsService,
    PaintTypesService,
    PanelsService,
    QuotesService,
    SetupsService,
    SpecialistChargesService,
    TimeRecordingsService,
    UsersService,
    VdaService,
    VehiclesService,
    VehicleSizesService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
