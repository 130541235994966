/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { DropdownModel } from '../models/dropdown-model';
import { EmailTemplateSummaryModel } from '../models/email-template-summary-model';
import { EmailTimerModel } from '../models/email-timer-model';
import { EmailTimerQueryModel } from '../models/email-timer-query-model';

@Injectable({ providedIn: 'root' })
export class AutoEmailService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllTemplates()` */
  static readonly GetAllTemplatesPath = '/get-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTemplates$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTemplates$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<EmailTemplateSummaryModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AutoEmailService.GetAllTemplatesPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmailTemplateSummaryModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTemplates$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTemplates$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<Array<EmailTemplateSummaryModel>> {
    return this.getAllTemplates$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmailTemplateSummaryModel>>): Array<EmailTemplateSummaryModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTemplates$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTemplates$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<EmailTemplateSummaryModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AutoEmailService.GetAllTemplatesPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmailTemplateSummaryModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTemplates$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTemplates$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<Array<EmailTemplateSummaryModel>> {
    return this.getAllTemplates$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmailTemplateSummaryModel>>): Array<EmailTemplateSummaryModel> => r.body)
    );
  }

  /** Path part for operation `getAllStatus()` */
  static readonly GetAllStatusPath = '/get-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStatus$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<DropdownModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AutoEmailService.GetAllStatusPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DropdownModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStatus$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<Array<DropdownModel>> {
    return this.getAllStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DropdownModel>>): Array<DropdownModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllStatus$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStatus$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<DropdownModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AutoEmailService.GetAllStatusPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DropdownModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllStatus$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStatus$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<Array<DropdownModel>> {
    return this.getAllStatus$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DropdownModel>>): Array<DropdownModel> => r.body)
    );
  }

  /** Path part for operation `getEmailTimerSettings()` */
  static readonly GetEmailTimerSettingsPath = '/get-timer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmailTimerSettings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmailTimerSettings$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<EmailTimerModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AutoEmailService.GetEmailTimerSettingsPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmailTimerModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEmailTimerSettings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmailTimerSettings$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<Array<EmailTimerModel>> {
    return this.getEmailTimerSettings$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmailTimerModel>>): Array<EmailTimerModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmailTimerSettings$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmailTimerSettings$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<EmailTimerModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AutoEmailService.GetEmailTimerSettingsPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmailTimerModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEmailTimerSettings$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmailTimerSettings$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<Array<EmailTimerModel>> {
    return this.getEmailTimerSettings$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmailTimerModel>>): Array<EmailTimerModel> => r.body)
    );
  }

  /** Path part for operation `setEmailTimer()` */
  static readonly SetEmailTimerPath = '/set-timer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setEmailTimer$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setEmailTimer$Plain$Response(
    params?: {
      body?: Array<EmailTimerQueryModel>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AutoEmailService.SetEmailTimerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setEmailTimer$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setEmailTimer$Plain(
    params?: {
      body?: Array<EmailTimerQueryModel>
    },
    context?: HttpContext
  ): Observable<string> {
    return this.setEmailTimer$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setEmailTimer$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setEmailTimer$Json$Response(
    params?: {
      body?: Array<EmailTimerQueryModel>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AutoEmailService.SetEmailTimerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setEmailTimer$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setEmailTimer$Json(
    params?: {
      body?: Array<EmailTimerQueryModel>
    },
    context?: HttpContext
  ): Observable<string> {
    return this.setEmailTimer$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
