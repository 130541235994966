/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ChecklistModel } from '../models/checklist-model';
import { EstimateCaptureSmsResult } from '../models/estimate-capture-sms-result';
import { EstimateCaptureSummaryModel } from '../models/estimate-capture-summary-model';

@Injectable({ providedIn: 'root' })
export class CaptureService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `generateCode()` */
  static readonly GenerateCodePath = '/capture/generate-code';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateCode$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateCode$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CaptureService.GenerateCodePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateCode$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateCode$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.generateCode$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateCode$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateCode$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CaptureService.GenerateCodePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateCode$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateCode$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.generateCode$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `sendCode()` */
  static readonly SendCodePath = '/capture/send-code';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCode$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendCode$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateCaptureSmsResult>> {
    const rb = new RequestBuilder(this.rootUrl, CaptureService.SendCodePath, 'put');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateCaptureSmsResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendCode$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendCode$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateCaptureSmsResult> {
    return this.sendCode$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateCaptureSmsResult>): EstimateCaptureSmsResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCode$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendCode$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateCaptureSmsResult>> {
    const rb = new RequestBuilder(this.rootUrl, CaptureService.SendCodePath, 'put');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateCaptureSmsResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendCode$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendCode$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateCaptureSmsResult> {
    return this.sendCode$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateCaptureSmsResult>): EstimateCaptureSmsResult => r.body)
    );
  }

  /** Path part for operation `getInfo()` */
  static readonly GetInfoPath = '/capture/get-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInfo$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInfo$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateCaptureSmsResult>> {
    const rb = new RequestBuilder(this.rootUrl, CaptureService.GetInfoPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateCaptureSmsResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInfo$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInfo$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateCaptureSmsResult> {
    return this.getInfo$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateCaptureSmsResult>): EstimateCaptureSmsResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInfo$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInfo$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateCaptureSmsResult>> {
    const rb = new RequestBuilder(this.rootUrl, CaptureService.GetInfoPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateCaptureSmsResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInfo$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInfo$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateCaptureSmsResult> {
    return this.getInfo$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateCaptureSmsResult>): EstimateCaptureSmsResult => r.body)
    );
  }

  /** Path part for operation `getDetails()` */
  static readonly GetDetailsPath = '/capture';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetails$Plain$Response(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateCaptureSummaryModel>> {
    const rb = new RequestBuilder(this.rootUrl, CaptureService.GetDetailsPath, 'get');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateCaptureSummaryModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetails$Plain(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<EstimateCaptureSummaryModel> {
    return this.getDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateCaptureSummaryModel>): EstimateCaptureSummaryModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDetails$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetails$Json$Response(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateCaptureSummaryModel>> {
    const rb = new RequestBuilder(this.rootUrl, CaptureService.GetDetailsPath, 'get');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateCaptureSummaryModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDetails$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetails$Json(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<EstimateCaptureSummaryModel> {
    return this.getDetails$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateCaptureSummaryModel>): EstimateCaptureSummaryModel => r.body)
    );
  }

  /** Path part for operation `upload()` */
  static readonly UploadPath = '/capture';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upload()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  upload$Response(
    params?: {
      code?: string;
      body?: {
'side'?: string;
'img'?: string;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CaptureService.UploadPath, 'post');
    if (params) {
      rb.query('code', params.code, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upload$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  upload(
    params?: {
      code?: string;
      body?: {
'side'?: string;
'img'?: string;
}
    },
    context?: HttpContext
  ): Observable<void> {
    return this.upload$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getChecklist()` */
  static readonly GetChecklistPath = '/capture/checklist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChecklist$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChecklist$Plain$Response(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistModel>> {
    const rb = new RequestBuilder(this.rootUrl, CaptureService.GetChecklistPath, 'get');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChecklist$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChecklist$Plain(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<ChecklistModel> {
    return this.getChecklist$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistModel>): ChecklistModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChecklist$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChecklist$Json$Response(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistModel>> {
    const rb = new RequestBuilder(this.rootUrl, CaptureService.GetChecklistPath, 'get');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChecklist$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChecklist$Json(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<ChecklistModel> {
    return this.getChecklist$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistModel>): ChecklistModel => r.body)
    );
  }

  /** Path part for operation `saveChecklist()` */
  static readonly SaveChecklistPath = '/capture/checklist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveChecklist()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveChecklist$Response(
    params?: {
      code?: string;
      body?: ChecklistModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CaptureService.SaveChecklistPath, 'post');
    if (params) {
      rb.query('code', params.code, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveChecklist$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveChecklist(
    params?: {
      code?: string;
      body?: ChecklistModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.saveChecklist$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
