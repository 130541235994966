<div class="module-picker-container">
  <h2>Select a Cost Centre</h2>
  <mat-dialog-content>
    <div class="content-wrapper">
      <!-- Loading Progress Bar -->
      <div *ngIf="isLoading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>

      <!-- Content for Cost Centre Selection -->
      <div *ngIf="!isLoading">
        <!-- Search Box for Cost Centres -->
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Search Cost Centre (Code or Name)</mat-label>
          <input type="text" matInput [formControl]="searchControl" autocomplete="off">
        </mat-form-field>

        <!-- Cost Centre List -->
        <div class="module-options">
          <div class="module-card" *ngFor="let costCentre of filteredCostCentres"
            (click)="selectCostCentre(costCentre)">
            <div class="module-title">
              <span class="cost-centre-code">{{ costCentre.code }}</span> -
              <span class="cost-centre-name">{{ costCentre.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <!-- Counter Display at the Bottom -->
    <div *ngIf="!isLoading" class="counter-info">
      Showing {{ filteredCostCentres.length }} of {{ costCentres.length }} Cost Centres
    </div>
  </mat-dialog-actions>
</div>