import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { UsersService } from 'api';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ModulePickerComponent } from '../module-picker/module-picker.component';
import { CCPickerComponent } from '../cc-picker/cc-picker.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public isLoading = true;

  constructor(
    private auth: AuthService,
    private userService: UsersService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.auth.isAuthenticated$.pipe(takeUntil(this.destroy$)).subscribe({
      next: isAuthenticated => {
        if (isAuthenticated) {
          this.isLoading = true;
          this.getUserRoles();
        } else {
          this.redirectToLogin('User not authenticated. Redirecting to login...');
        }
      },
      error: err => {
        console.error('Authentication error:', err);
        this.redirectToLogin('Session expired or an error occurred. Redirecting to login...');
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getUserRoles() {
    this.userService.getUserRoles$Json().pipe(takeUntil(this.destroy$)).subscribe({
      next: roles => {
        this.isLoading = false;
        if (roles.length > 1) {
          this.openModulePickerDialog(roles);
        } else {
          this.handleModuleSelection(roles[0]);
        }
      },
      error: err => {
        console.error('Failed to get user roles:', err);
        alert('Error getting roles!');
        this.redirectToLogin('Error while fetching user roles. Redirecting to login...');
      }
    });
  }

  private openModulePickerDialog(roles: string[]): void {
    const dialogRef = this.dialog.open(ModulePickerComponent, {
      width: '450px',
      data: { roles },
      disableClose: true
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(selectedModule => {
      if (selectedModule) {
        this.handleModuleSelection(selectedModule);
      } else {
        this.redirectToLogin('No module selected. Redirecting to login...');
      }
    });
  }

  public handleModuleSelection(module: string) {
    this.isLoading = true;
    this.userService.hasCostCentre$Json().pipe(takeUntil(this.destroy$)).subscribe({
      next: hasCostCentre => {
        this.isLoading = false;
        if (!hasCostCentre) {
          this.openCostCentrePickerDialog(module);
        } else {
          this.goToModule(module);
        }
      },
      error: err => {
        console.error('Failed to verify cost centre:', err);
        alert('Error verifying cost centre.');
        this.isLoading = false;
      }
    });
  }

  private openCostCentrePickerDialog(module: string): void {
    const dialogRef = this.dialog.open(CCPickerComponent, {
      width: '450px',
      data: { module },
      disableClose: true
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(selectedCostCentre => {
      if (selectedCostCentre) {
        this.isLoading = true;
        this.userService.selectCostCentre({ companyId: selectedCostCentre })
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              this.isLoading = false;
              this.goToModule(module);
            },
            error: err => {
              console.error('Failed to select cost centre:', err);
              alert('Error selecting cost centre. Please try again.');
              this.isLoading = false;
            }
          });
      } else {
        this.redirectToLogin('No cost centre selected. Redirecting to login...');
      }
    });
  }

  private goToModule(module: string) {
    this.isLoading = false;
    switch (module.toUpperCase()) {
      case 'ADMIN':
        window.location.href = '/admin';
        break;
      case 'ESTIMATES':
        window.location.href = '/estimates';
        break;
      default:
        alert('Invalid request!');
        this.redirectToLogin('Invalid module selection. Redirecting to login...');
        break;
    }
  }

  private redirectToLogin(message: string) {
    console.warn(message);
    window.location.href = environment.auth.ssoRoot;
  }
}
