/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { GuidStringKeyValuePair } from '../models/guid-string-key-value-pair';
import { VdaDamageTypeMappingCountModel } from '../models/vda-damage-type-mapping-count-model';
import { VdaDamageTypeMappingQueryModel } from '../models/vda-damage-type-mapping-query-model';
import { VdaPanelMappingCountModel } from '../models/vda-panel-mapping-count-model';
import { VdaPanelMappingQueryModel } from '../models/vda-panel-mapping-query-model';
import { VehicleDamageAssessmentAttachmentModel } from '../models/vehicle-damage-assessment-attachment-model';

@Injectable({ providedIn: 'root' })
export class VdaService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getVdaPanelMappingCount()` */
  static readonly GetVdaPanelMappingCountPath = '/Vda/get-count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVdaPanelMappingCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaPanelMappingCount$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VdaPanelMappingCountModel>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetVdaPanelMappingCountPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VdaPanelMappingCountModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVdaPanelMappingCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaPanelMappingCount$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<VdaPanelMappingCountModel> {
    return this.getVdaPanelMappingCount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<VdaPanelMappingCountModel>): VdaPanelMappingCountModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVdaPanelMappingCount$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaPanelMappingCount$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VdaPanelMappingCountModel>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetVdaPanelMappingCountPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VdaPanelMappingCountModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVdaPanelMappingCount$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaPanelMappingCount$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<VdaPanelMappingCountModel> {
    return this.getVdaPanelMappingCount$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<VdaPanelMappingCountModel>): VdaPanelMappingCountModel => r.body)
    );
  }

  /** Path part for operation `getVdaPanelList()` */
  static readonly GetVdaPanelListPath = '/Vda/get-vdapanel-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVdaPanelList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaPanelList$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GuidStringKeyValuePair>>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetVdaPanelListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GuidStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVdaPanelList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaPanelList$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<GuidStringKeyValuePair>> {
    return this.getVdaPanelList$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GuidStringKeyValuePair>>): Array<GuidStringKeyValuePair> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVdaPanelList$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaPanelList$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GuidStringKeyValuePair>>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetVdaPanelListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GuidStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVdaPanelList$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaPanelList$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<GuidStringKeyValuePair>> {
    return this.getVdaPanelList$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GuidStringKeyValuePair>>): Array<GuidStringKeyValuePair> => r.body)
    );
  }

  /** Path part for operation `getPanelList()` */
  static readonly GetPanelListPath = '/Vda/get-panel-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPanelList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPanelList$Plain$Response(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetPanelListPath, 'get');
    if (params) {
      rb.query('key', params.key, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPanelList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPanelList$Plain(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getPanelList$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPanelList$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPanelList$Json$Response(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetPanelListPath, 'get');
    if (params) {
      rb.query('key', params.key, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPanelList$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPanelList$Json(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getPanelList$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getVdaMappingList()` */
  static readonly GetVdaMappingListPath = '/Vda/get-mapping-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVdaMappingList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaMappingList$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetVdaMappingListPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVdaMappingList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaMappingList$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getVdaMappingList$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVdaMappingList$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaMappingList$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetVdaMappingListPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVdaMappingList$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaMappingList$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getVdaMappingList$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `saveVdaMappingList()` */
  static readonly SaveVdaMappingListPath = '/Vda/save-mapping-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveVdaMappingList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveVdaMappingList$Response(
    params?: {
      body?: Array<VdaPanelMappingQueryModel>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.SaveVdaMappingListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveVdaMappingList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveVdaMappingList(
    params?: {
      body?: Array<VdaPanelMappingQueryModel>
    },
    context?: HttpContext
  ): Observable<void> {
    return this.saveVdaMappingList$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getMappedPanels()` */
  static readonly GetMappedPanelsPath = '/Vda/get-mapped-panels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMappedPanels$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMappedPanels$Plain$Response(
    params?: {
      panel?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetMappedPanelsPath, 'get');
    if (params) {
      rb.query('panel', params.panel, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMappedPanels$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMappedPanels$Plain(
    params?: {
      panel?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getMappedPanels$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMappedPanels$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMappedPanels$Json$Response(
    params?: {
      panel?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetMappedPanelsPath, 'get');
    if (params) {
      rb.query('panel', params.panel, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMappedPanels$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMappedPanels$Json(
    params?: {
      panel?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getMappedPanels$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getVdaDamageTypeMappingCount()` */
  static readonly GetVdaDamageTypeMappingCountPath = '/Vda/get-damage-type-count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVdaDamageTypeMappingCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaDamageTypeMappingCount$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VdaDamageTypeMappingCountModel>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetVdaDamageTypeMappingCountPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VdaDamageTypeMappingCountModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVdaDamageTypeMappingCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaDamageTypeMappingCount$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<VdaDamageTypeMappingCountModel> {
    return this.getVdaDamageTypeMappingCount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<VdaDamageTypeMappingCountModel>): VdaDamageTypeMappingCountModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVdaDamageTypeMappingCount$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaDamageTypeMappingCount$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VdaDamageTypeMappingCountModel>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetVdaDamageTypeMappingCountPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VdaDamageTypeMappingCountModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVdaDamageTypeMappingCount$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaDamageTypeMappingCount$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<VdaDamageTypeMappingCountModel> {
    return this.getVdaDamageTypeMappingCount$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<VdaDamageTypeMappingCountModel>): VdaDamageTypeMappingCountModel => r.body)
    );
  }

  /** Path part for operation `getVdaDamageTypeList()` */
  static readonly GetVdaDamageTypeListPath = '/Vda/get-vda-damage-type-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVdaDamageTypeList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaDamageTypeList$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GuidStringKeyValuePair>>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetVdaDamageTypeListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GuidStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVdaDamageTypeList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaDamageTypeList$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<GuidStringKeyValuePair>> {
    return this.getVdaDamageTypeList$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GuidStringKeyValuePair>>): Array<GuidStringKeyValuePair> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVdaDamageTypeList$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaDamageTypeList$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GuidStringKeyValuePair>>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetVdaDamageTypeListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GuidStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVdaDamageTypeList$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVdaDamageTypeList$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<GuidStringKeyValuePair>> {
    return this.getVdaDamageTypeList$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GuidStringKeyValuePair>>): Array<GuidStringKeyValuePair> => r.body)
    );
  }

  /** Path part for operation `getDamageTypeList()` */
  static readonly GetDamageTypeListPath = '/Vda/get-damage-type-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDamageTypeList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDamageTypeList$Plain$Response(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetDamageTypeListPath, 'get');
    if (params) {
      rb.query('key', params.key, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDamageTypeList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDamageTypeList$Plain(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getDamageTypeList$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDamageTypeList$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDamageTypeList$Json$Response(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetDamageTypeListPath, 'get');
    if (params) {
      rb.query('key', params.key, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDamageTypeList$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDamageTypeList$Json(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getDamageTypeList$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getAllDamageTypeList()` */
  static readonly GetAllDamageTypeListPath = '/Vda/get-all-damage-type-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDamageTypeList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDamageTypeList$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetAllDamageTypeListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllDamageTypeList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDamageTypeList$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getAllDamageTypeList$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDamageTypeList$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDamageTypeList$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetAllDamageTypeListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllDamageTypeList$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDamageTypeList$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getAllDamageTypeList$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getDamageTypeMappingList()` */
  static readonly GetDamageTypeMappingListPath = '/Vda/get-damage-type-mapping-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDamageTypeMappingList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDamageTypeMappingList$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetDamageTypeMappingListPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDamageTypeMappingList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDamageTypeMappingList$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getDamageTypeMappingList$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDamageTypeMappingList$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDamageTypeMappingList$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetDamageTypeMappingListPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDamageTypeMappingList$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDamageTypeMappingList$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getDamageTypeMappingList$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `saveDamageTypeMappingList()` */
  static readonly SaveDamageTypeMappingListPath = '/Vda/save-damage-type-mapping-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveDamageTypeMappingList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveDamageTypeMappingList$Response(
    params?: {
      body?: Array<VdaDamageTypeMappingQueryModel>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.SaveDamageTypeMappingListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveDamageTypeMappingList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveDamageTypeMappingList(
    params?: {
      body?: Array<VdaDamageTypeMappingQueryModel>
    },
    context?: HttpContext
  ): Observable<void> {
    return this.saveDamageTypeMappingList$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getMappedDamageTypes()` */
  static readonly GetMappedDamageTypesPath = '/Vda/get-mapped-damage-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMappedDamageTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMappedDamageTypes$Plain$Response(
    params?: {
      damageType?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetMappedDamageTypesPath, 'get');
    if (params) {
      rb.query('damageType', params.damageType, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMappedDamageTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMappedDamageTypes$Plain(
    params?: {
      damageType?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getMappedDamageTypes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMappedDamageTypes$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMappedDamageTypes$Json$Response(
    params?: {
      damageType?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.GetMappedDamageTypesPath, 'get');
    if (params) {
      rb.query('damageType', params.damageType, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMappedDamageTypes$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMappedDamageTypes$Json(
    params?: {
      damageType?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getMappedDamageTypes$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `vdaApiCall()` */
  static readonly VdaApiCallPath = '/Vda/api-call';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vdaApiCall$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  vdaApiCall$Plain$Response(
    params?: {
      attachmentId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VehicleDamageAssessmentAttachmentModel>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.VdaApiCallPath, 'post');
    if (params) {
      rb.query('attachmentId', params.attachmentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VehicleDamageAssessmentAttachmentModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vdaApiCall$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vdaApiCall$Plain(
    params?: {
      attachmentId?: string;
    },
    context?: HttpContext
  ): Observable<VehicleDamageAssessmentAttachmentModel> {
    return this.vdaApiCall$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleDamageAssessmentAttachmentModel>): VehicleDamageAssessmentAttachmentModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vdaApiCall$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  vdaApiCall$Json$Response(
    params?: {
      attachmentId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VehicleDamageAssessmentAttachmentModel>> {
    const rb = new RequestBuilder(this.rootUrl, VdaService.VdaApiCallPath, 'post');
    if (params) {
      rb.query('attachmentId', params.attachmentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VehicleDamageAssessmentAttachmentModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vdaApiCall$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vdaApiCall$Json(
    params?: {
      attachmentId?: string;
    },
    context?: HttpContext
  ): Observable<VehicleDamageAssessmentAttachmentModel> {
    return this.vdaApiCall$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleDamageAssessmentAttachmentModel>): VehicleDamageAssessmentAttachmentModel => r.body)
    );
  }

}
