// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  env: 'local',
  apiUrl: '../api-v3',
  auth: {
    "domain": "auth-dev.bodynetbms.com",
    "clientId": "o0V2zH0XHpGhcXGZEOw0vTwJpTk1aU3m",
    "authorizationParams": {
      "audience": "https://auth-dev.bodynetbms.com",
      redirect_uri: window.location.origin
    },
    "errorPath": "/autherror",
    "ssoRoot": 'http://sso-dev.bodynetbms.com',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
