/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { StringStringKeyValuePair } from '../models/string-string-key-value-pair';
import { VehicleInfoModel } from '../models/vehicle-info-model';
import { VehicleModel } from '../models/vehicle-model';
import { VrmResultModel } from '../models/vrm-result-model';

@Injectable({ providedIn: 'root' })
export class VehiclesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getNewVehicle()` */
  static readonly GetNewVehiclePath = '/vehicles/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewVehicle$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewVehicle$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VehicleModel>> {
    const rb = new RequestBuilder(this.rootUrl, VehiclesService.GetNewVehiclePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VehicleModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewVehicle$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewVehicle$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<VehicleModel> {
    return this.getNewVehicle$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleModel>): VehicleModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewVehicle$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewVehicle$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VehicleModel>> {
    const rb = new RequestBuilder(this.rootUrl, VehiclesService.GetNewVehiclePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VehicleModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewVehicle$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewVehicle$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<VehicleModel> {
    return this.getNewVehicle$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleModel>): VehicleModel => r.body)
    );
  }

  /** Path part for operation `getVehicle()` */
  static readonly GetVehiclePath = '/vehicles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicle$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicle$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VehicleModel>> {
    const rb = new RequestBuilder(this.rootUrl, VehiclesService.GetVehiclePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VehicleModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicle$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicle$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<VehicleModel> {
    return this.getVehicle$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleModel>): VehicleModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicle$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicle$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VehicleModel>> {
    const rb = new RequestBuilder(this.rootUrl, VehiclesService.GetVehiclePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VehicleModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicle$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicle$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<VehicleModel> {
    return this.getVehicle$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleModel>): VehicleModel => r.body)
    );
  }

  /** Path part for operation `putVehicle()` */
  static readonly PutVehiclePath = '/vehicles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putVehicle()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putVehicle$Response(
    params?: {
      id?: string;
      body?: VehicleModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VehiclesService.PutVehiclePath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putVehicle$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putVehicle(
    params?: {
      id?: string;
      body?: VehicleModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.putVehicle$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getMileage()` */
  static readonly GetMileagePath = '/vehicles/mileage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMileage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMileage$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, VehiclesService.GetMileagePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMileage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMileage$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getMileage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMileage$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMileage$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, VehiclesService.GetMileagePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMileage$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMileage$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getMileage$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `putMileage()` */
  static readonly PutMileagePath = '/vehicles/mileage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMileage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putMileage$Response(
    params?: {
      id?: string;
      body?: StringStringKeyValuePair
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VehiclesService.PutMileagePath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putMileage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putMileage(
    params?: {
      id?: string;
      body?: StringStringKeyValuePair
    },
    context?: HttpContext
  ): Observable<void> {
    return this.putMileage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getVehicleInfo()` */
  static readonly GetVehicleInfoPath = '/vehicles/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleInfo$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleInfo$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VehicleInfoModel>> {
    const rb = new RequestBuilder(this.rootUrl, VehiclesService.GetVehicleInfoPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VehicleInfoModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicleInfo$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleInfo$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<VehicleInfoModel> {
    return this.getVehicleInfo$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleInfoModel>): VehicleInfoModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleInfo$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleInfo$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VehicleInfoModel>> {
    const rb = new RequestBuilder(this.rootUrl, VehiclesService.GetVehicleInfoPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VehicleInfoModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicleInfo$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleInfo$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<VehicleInfoModel> {
    return this.getVehicleInfo$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<VehicleInfoModel>): VehicleInfoModel => r.body)
    );
  }

  /** Path part for operation `getVrm()` */
  static readonly GetVrmPath = '/vehicles/VRM';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVrm$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVrm$Plain$Response(
    params?: {
      regNo?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VrmResultModel>> {
    const rb = new RequestBuilder(this.rootUrl, VehiclesService.GetVrmPath, 'get');
    if (params) {
      rb.query('regNo', params.regNo, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VrmResultModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVrm$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVrm$Plain(
    params?: {
      regNo?: string;
    },
    context?: HttpContext
  ): Observable<VrmResultModel> {
    return this.getVrm$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<VrmResultModel>): VrmResultModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVrm$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVrm$Json$Response(
    params?: {
      regNo?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VrmResultModel>> {
    const rb = new RequestBuilder(this.rootUrl, VehiclesService.GetVrmPath, 'get');
    if (params) {
      rb.query('regNo', params.regNo, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VrmResultModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVrm$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVrm$Json(
    params?: {
      regNo?: string;
    },
    context?: HttpContext
  ): Observable<VrmResultModel> {
    return this.getVrm$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<VrmResultModel>): VrmResultModel => r.body)
    );
  }

  /** Path part for operation `putVrm()` */
  static readonly PutVrmPath = '/vehicles/VRM';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putVrm()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putVrm$Response(
    params?: {
      id?: string;
      body?: VrmResultModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VehiclesService.PutVrmPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putVrm$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putVrm(
    params?: {
      id?: string;
      body?: VrmResultModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.putVrm$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
