/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BmwUsedModel } from '../models/bmw-used-model';
import { MotorserveModel } from '../models/motorserve-model';
import { SytnerModel } from '../models/sytner-model';

@Injectable({ providedIn: 'root' })
export class FormsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getBmwUsed()` */
  static readonly GetBmwUsedPath = '/forms/bmw-used';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBmwUsed$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBmwUsed$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BmwUsedModel>> {
    const rb = new RequestBuilder(this.rootUrl, FormsService.GetBmwUsedPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BmwUsedModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBmwUsed$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBmwUsed$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<BmwUsedModel> {
    return this.getBmwUsed$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BmwUsedModel>): BmwUsedModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBmwUsed$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBmwUsed$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BmwUsedModel>> {
    const rb = new RequestBuilder(this.rootUrl, FormsService.GetBmwUsedPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BmwUsedModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBmwUsed$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBmwUsed$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<BmwUsedModel> {
    return this.getBmwUsed$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BmwUsedModel>): BmwUsedModel => r.body)
    );
  }

  /** Path part for operation `updateBmwUsed()` */
  static readonly UpdateBmwUsedPath = '/forms/bmw-used';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBmwUsed()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBmwUsed$Response(
    params?: {
      id?: string;
      body?: BmwUsedModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FormsService.UpdateBmwUsedPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBmwUsed$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBmwUsed(
    params?: {
      id?: string;
      body?: BmwUsedModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateBmwUsed$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateBmwUsed_1()` */
  static readonly UpdateBmwUsed_1Path = '/forms/bmw-used';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBmwUsed_1()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBmwUsed_1$Response(
    params?: {
      id?: string;
      body?: BmwUsedModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FormsService.UpdateBmwUsed_1Path, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBmwUsed_1$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBmwUsed_1(
    params?: {
      id?: string;
      body?: BmwUsedModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateBmwUsed_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getSytner()` */
  static readonly GetSytnerPath = '/forms/sytner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSytner$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSytner$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SytnerModel>> {
    const rb = new RequestBuilder(this.rootUrl, FormsService.GetSytnerPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SytnerModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSytner$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSytner$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<SytnerModel> {
    return this.getSytner$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SytnerModel>): SytnerModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSytner$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSytner$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SytnerModel>> {
    const rb = new RequestBuilder(this.rootUrl, FormsService.GetSytnerPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SytnerModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSytner$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSytner$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<SytnerModel> {
    return this.getSytner$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SytnerModel>): SytnerModel => r.body)
    );
  }

  /** Path part for operation `updateSytnerPre()` */
  static readonly UpdateSytnerPrePath = '/forms/sytner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSytnerPre()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateSytnerPre$Response(
    params?: {
      id?: string;
      body?: SytnerModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FormsService.UpdateSytnerPrePath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSytnerPre$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateSytnerPre(
    params?: {
      id?: string;
      body?: SytnerModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateSytnerPre$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getForm()` */
  static readonly GetFormPath = '/forms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForm$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForm$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MotorserveModel>> {
    const rb = new RequestBuilder(this.rootUrl, FormsService.GetFormPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MotorserveModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForm$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForm$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MotorserveModel> {
    return this.getForm$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MotorserveModel>): MotorserveModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForm$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForm$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MotorserveModel>> {
    const rb = new RequestBuilder(this.rootUrl, FormsService.GetFormPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MotorserveModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForm$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForm$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MotorserveModel> {
    return this.getForm$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MotorserveModel>): MotorserveModel => r.body)
    );
  }

  /** Path part for operation `putForm()` */
  static readonly PutFormPath = '/forms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putForm()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putForm$Response(
    params?: {
      id?: string;
      log?: boolean;
      type?: string;
      body?: MotorserveModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FormsService.PutFormPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('log', params.log, {});
      rb.query('type', params.type, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putForm$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putForm(
    params?: {
      id?: string;
      log?: boolean;
      type?: string;
      body?: MotorserveModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.putForm$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createForm()` */
  static readonly CreateFormPath = '/forms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createForm$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createForm$Plain$Response(
    params?: {
      body?: MotorserveModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, FormsService.CreateFormPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createForm$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createForm$Plain(
    params?: {
      body?: MotorserveModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.createForm$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createForm$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createForm$Json$Response(
    params?: {
      body?: MotorserveModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, FormsService.CreateFormPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createForm$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createForm$Json(
    params?: {
      body?: MotorserveModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.createForm$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
