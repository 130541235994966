import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { authGuardFn } from '@auth0/auth0-angular';
import { AuthErrorComponent } from './auth-error/auth-error.component';
import { AppComponent } from './app.component';
import { CcResetComponent } from './cc-reset/cc-reset.component';


const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full'
      },
      {
        path: 'auth',
        component: AuthComponent,
        canActivate: [authGuardFn]
      },
      {
        path: 'cc-reset',
        component: CcResetComponent,
        canActivate: [authGuardFn]
      },
      {
        path: 'autherror',
        component: AuthErrorComponent
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
