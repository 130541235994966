/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


@Injectable({ providedIn: 'root' })
export class FinancingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAsfStatus()` */
  static readonly GetAsfStatusPath = '/financing/asf-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAsfStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfStatus$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, FinancingService.GetAsfStatusPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAsfStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfStatus$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.getAsfStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAsfStatus$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfStatus$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, FinancingService.GetAsfStatusPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAsfStatus$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfStatus$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.getAsfStatus$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `putAsfStatus()` */
  static readonly PutAsfStatusPath = '/financing/asf-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAsfStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  putAsfStatus$Response(
    params?: {
      key?: string;
      status?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FinancingService.PutAsfStatusPath, 'put');
    if (params) {
      rb.query('key', params.key, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putAsfStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  putAsfStatus(
    params?: {
      key?: string;
      status?: boolean;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.putAsfStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getLocalUrl()` */
  static readonly GetLocalUrlPath = '/financing/url';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocalUrl$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocalUrl$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, FinancingService.GetLocalUrlPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLocalUrl$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocalUrl$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getLocalUrl$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocalUrl$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocalUrl$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, FinancingService.GetLocalUrlPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLocalUrl$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocalUrl$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getLocalUrl$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getAsfPayment()` */
  static readonly GetAsfPaymentPath = '/financing/asf-payment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAsfPayment$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfPayment$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, FinancingService.GetAsfPaymentPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAsfPayment$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfPayment$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getAsfPayment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAsfPayment$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfPayment$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, FinancingService.GetAsfPaymentPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAsfPayment$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfPayment$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getAsfPayment$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getAsfInstallment()` */
  static readonly GetAsfInstallmentPath = '/financing/asf-installment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAsfInstallment$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfInstallment$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, FinancingService.GetAsfInstallmentPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAsfInstallment$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfInstallment$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getAsfInstallment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAsfInstallment$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfInstallment$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, FinancingService.GetAsfInstallmentPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAsfInstallment$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfInstallment$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getAsfInstallment$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getAsfPage()` */
  static readonly GetAsfPagePath = '/financing/asf-page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAsfPage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfPage$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, FinancingService.GetAsfPagePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAsfPage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfPage$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getAsfPage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAsfPage$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfPage$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, FinancingService.GetAsfPagePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAsfPage$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsfPage$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getAsfPage$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
