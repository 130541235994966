/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { DropdownModelResultListingModel } from '../models/dropdown-model-result-listing-model';
import { EstimateStatusModel } from '../models/estimate-status-model';
import { EstimateStatusQueryModel } from '../models/estimate-status-query-model';

@Injectable({ providedIn: 'root' })
export class EstimateStatusService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getListing()` */
  static readonly GetListingPath = '/EstimateStatus/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getListing()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getListing$Response(
    params?: {
      body?: EstimateStatusQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateStatusService.GetListingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getListing$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getListing(
    params?: {
      body?: EstimateStatusQueryModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.getListing$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getDropdown()` */
  static readonly GetDropdownPath = '/EstimateStatus/list-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDropdown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDropdown$Plain$Response(
    params?: {
      companyId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateStatusService.GetDropdownPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDropdown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDropdown$Plain(
    params?: {
      companyId?: string;
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getDropdown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDropdown$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDropdown$Json$Response(
    params?: {
      companyId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateStatusService.GetDropdownPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDropdown$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDropdown$Json(
    params?: {
      companyId?: string;
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getDropdown$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getEstimateStatus()` */
  static readonly GetEstimateStatusPath = '/EstimateStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimateStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateStatus$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateStatusModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateStatusService.GetEstimateStatusPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateStatusModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimateStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateStatus$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateStatusModel> {
    return this.getEstimateStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateStatusModel>): EstimateStatusModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimateStatus$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateStatus$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateStatusModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateStatusService.GetEstimateStatusPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateStatusModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimateStatus$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateStatus$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateStatusModel> {
    return this.getEstimateStatus$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateStatusModel>): EstimateStatusModel => r.body)
    );
  }

  /** Path part for operation `updateEstimateStatus()` */
  static readonly UpdateEstimateStatusPath = '/EstimateStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEstimateStatus()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateEstimateStatus$Response(
    params?: {
      id?: string;
      body?: EstimateStatusModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateStatusService.UpdateEstimateStatusPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateEstimateStatus$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateEstimateStatus(
    params?: {
      id?: string;
      body?: EstimateStatusModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateEstimateStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `insertEstimateStatus()` */
  static readonly InsertEstimateStatusPath = '/EstimateStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertEstimateStatus()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertEstimateStatus$Response(
    params?: {
      companyId?: string;
      body?: EstimateStatusModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateStatusService.InsertEstimateStatusPath, 'post');
    if (params) {
      rb.query('companyId', params.companyId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insertEstimateStatus$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertEstimateStatus(
    params?: {
      companyId?: string;
      body?: EstimateStatusModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.insertEstimateStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteEstimateStatus()` */
  static readonly DeleteEstimateStatusPath = '/EstimateStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEstimateStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEstimateStatus$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateStatusModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateStatusService.DeleteEstimateStatusPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateStatusModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEstimateStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEstimateStatus$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateStatusModel> {
    return this.deleteEstimateStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateStatusModel>): EstimateStatusModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEstimateStatus$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEstimateStatus$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateStatusModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateStatusService.DeleteEstimateStatusPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateStatusModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEstimateStatus$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEstimateStatus$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateStatusModel> {
    return this.deleteEstimateStatus$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateStatusModel>): EstimateStatusModel => r.body)
    );
  }

  /** Path part for operation `getNewEstimateStatus()` */
  static readonly GetNewEstimateStatusPath = '/EstimateStatus/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewEstimateStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewEstimateStatus$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateStatusModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateStatusService.GetNewEstimateStatusPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateStatusModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewEstimateStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewEstimateStatus$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<EstimateStatusModel> {
    return this.getNewEstimateStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateStatusModel>): EstimateStatusModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewEstimateStatus$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewEstimateStatus$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateStatusModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateStatusService.GetNewEstimateStatusPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateStatusModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewEstimateStatus$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewEstimateStatus$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<EstimateStatusModel> {
    return this.getNewEstimateStatus$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateStatusModel>): EstimateStatusModel => r.body)
    );
  }

  /** Path part for operation `isEstimateStatusInUse()` */
  static readonly IsEstimateStatusInUsePath = '/EstimateStatus/inuse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isEstimateStatusInUse$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  isEstimateStatusInUse$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateStatusService.IsEstimateStatusInUsePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isEstimateStatusInUse$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isEstimateStatusInUse$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.isEstimateStatusInUse$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isEstimateStatusInUse$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  isEstimateStatusInUse$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateStatusService.IsEstimateStatusInUsePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isEstimateStatusInUse$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isEstimateStatusInUse$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.isEstimateStatusInUse$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
