/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { EmailAddressModel } from '../models/email-address-model';
import { EmailModel } from '../models/email-model';
import { EmailQueryModel } from '../models/email-query-model';
import { EmailSummaryModelResultListingModel } from '../models/email-summary-model-result-listing-model';

@Injectable({ providedIn: 'root' })
export class EmailService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `testEmail()` */
  static readonly TestEmailPath = '/emails/test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `testEmail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  testEmail$Response(
    params?: {
      body?: EmailModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.TestEmailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `testEmail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  testEmail(
    params?: {
      body?: EmailModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.testEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getEmailListing()` */
  static readonly GetEmailListingPath = '/emails/listing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmailListing$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmailListing$Plain$Response(
    params?: {
      body?: EmailQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EmailSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.GetEmailListingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmailSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEmailListing$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmailListing$Plain(
    params?: {
      body?: EmailQueryModel
    },
    context?: HttpContext
  ): Observable<EmailSummaryModelResultListingModel> {
    return this.getEmailListing$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailSummaryModelResultListingModel>): EmailSummaryModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmailListing$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmailListing$Json$Response(
    params?: {
      body?: EmailQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EmailSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.GetEmailListingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmailSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEmailListing$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmailListing$Json(
    params?: {
      body?: EmailQueryModel
    },
    context?: HttpContext
  ): Observable<EmailSummaryModelResultListingModel> {
    return this.getEmailListing$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailSummaryModelResultListingModel>): EmailSummaryModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `clearLogs()` */
  static readonly ClearLogsPath = '/emails/logs/clear';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clearLogs$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clearLogs$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.ClearLogsPath, 'delete');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clearLogs$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clearLogs$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<number> {
    return this.clearLogs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clearLogs$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  clearLogs$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.ClearLogsPath, 'delete');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clearLogs$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clearLogs$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<number> {
    return this.clearLogs$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `checkLogExists()` */
  static readonly CheckLogExistsPath = '/emails/logs/exist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkLogExists$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkLogExists$Plain$Response(
    params?: {
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.CheckLogExistsPath, 'get');
    if (params) {
      rb.query('estimateId', params.estimateId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkLogExists$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkLogExists$Plain(
    params?: {
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.checkLogExists$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkLogExists$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkLogExists$Json$Response(
    params?: {
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.CheckLogExistsPath, 'get');
    if (params) {
      rb.query('estimateId', params.estimateId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkLogExists$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkLogExists$Json(
    params?: {
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.checkLogExists$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getRecentRecipients()` */
  static readonly GetRecentRecipientsPath = '/emails/recent/recipients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecentRecipients$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentRecipients$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.GetRecentRecipientsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecentRecipients$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentRecipients$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getRecentRecipients$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecentRecipients$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentRecipients$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.GetRecentRecipientsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecentRecipients$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentRecipients$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getRecentRecipients$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `excludeRecipients()` */
  static readonly ExcludeRecipientsPath = '/emails/recent/recipients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `excludeRecipients()` instead.
   *
   * This method doesn't expect any request body.
   */
  excludeRecipients$Response(
    params?: {
      emails?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.ExcludeRecipientsPath, 'delete');
    if (params) {
      rb.query('emails', params.emails, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `excludeRecipients$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  excludeRecipients(
    params?: {
      emails?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.excludeRecipients$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getRecentSenders()` */
  static readonly GetRecentSendersPath = '/emails/recent/senders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecentSenders$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentSenders$Plain$Response(
    params?: {
      type?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<EmailAddressModel>>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.GetRecentSendersPath, 'get');
    if (params) {
      rb.query('type', params.type, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmailAddressModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecentSenders$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentSenders$Plain(
    params?: {
      type?: string;
    },
    context?: HttpContext
  ): Observable<Array<EmailAddressModel>> {
    return this.getRecentSenders$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmailAddressModel>>): Array<EmailAddressModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecentSenders$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentSenders$Json$Response(
    params?: {
      type?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<EmailAddressModel>>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.GetRecentSendersPath, 'get');
    if (params) {
      rb.query('type', params.type, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmailAddressModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecentSenders$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentSenders$Json(
    params?: {
      type?: string;
    },
    context?: HttpContext
  ): Observable<Array<EmailAddressModel>> {
    return this.getRecentSenders$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmailAddressModel>>): Array<EmailAddressModel> => r.body)
    );
  }

}
