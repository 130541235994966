/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { PaintType } from '../models/paint-type';
import { PaintTypesModel } from '../models/paint-types-model';
import { PaintTypesQueryModel } from '../models/paint-types-query-model';
import { PaintTypesSummaryModelResultListingModel } from '../models/paint-types-summary-model-result-listing-model';

@Injectable({ providedIn: 'root' })
export class PaintTypesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPagedPaintTypes()` */
  static readonly GetPagedPaintTypesPath = '/paint-types/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPagedPaintTypes$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPagedPaintTypes$Plain$Response(
    params?: {
      body?: PaintTypesQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaintTypesSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaintTypesService.GetPagedPaintTypesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaintTypesSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPagedPaintTypes$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPagedPaintTypes$Plain(
    params?: {
      body?: PaintTypesQueryModel
    },
    context?: HttpContext
  ): Observable<PaintTypesSummaryModelResultListingModel> {
    return this.getPagedPaintTypes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaintTypesSummaryModelResultListingModel>): PaintTypesSummaryModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPagedPaintTypes$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPagedPaintTypes$Json$Response(
    params?: {
      body?: PaintTypesQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaintTypesSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaintTypesService.GetPagedPaintTypesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaintTypesSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPagedPaintTypes$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPagedPaintTypes$Json(
    params?: {
      body?: PaintTypesQueryModel
    },
    context?: HttpContext
  ): Observable<PaintTypesSummaryModelResultListingModel> {
    return this.getPagedPaintTypes$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaintTypesSummaryModelResultListingModel>): PaintTypesSummaryModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getPaintType()` */
  static readonly GetPaintTypePath = '/paint-types/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaintType$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaintType$Plain$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaintTypesModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaintTypesService.GetPaintTypePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaintTypesModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaintType$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaintType$Plain(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<PaintTypesModel> {
    return this.getPaintType$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaintTypesModel>): PaintTypesModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaintType$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaintType$Json$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaintTypesModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaintTypesService.GetPaintTypePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaintTypesModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaintType$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaintType$Json(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<PaintTypesModel> {
    return this.getPaintType$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaintTypesModel>): PaintTypesModel => r.body)
    );
  }

  /** Path part for operation `updatePaintType()` */
  static readonly UpdatePaintTypePath = '/paint-types/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePaintType()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePaintType$Response(
    params: {
      id: string;
      body?: PaintTypesModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PaintTypesService.UpdatePaintTypePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePaintType$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePaintType(
    params: {
      id: string;
      body?: PaintTypesModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updatePaintType$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deletePaintType()` */
  static readonly DeletePaintTypePath = '/paint-types/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePaintType$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePaintType$Plain$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaintTypesModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaintTypesService.DeletePaintTypePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaintTypesModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePaintType$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePaintType$Plain(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<PaintTypesModel> {
    return this.deletePaintType$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaintTypesModel>): PaintTypesModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePaintType$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePaintType$Json$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaintTypesModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaintTypesService.DeletePaintTypePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaintTypesModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePaintType$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePaintType$Json(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<PaintTypesModel> {
    return this.deletePaintType$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaintTypesModel>): PaintTypesModel => r.body)
    );
  }

  /** Path part for operation `getNewPaintType()` */
  static readonly GetNewPaintTypePath = '/paint-types/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewPaintType$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewPaintType$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaintTypesModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaintTypesService.GetNewPaintTypePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaintTypesModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewPaintType$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewPaintType$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<PaintTypesModel> {
    return this.getNewPaintType$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaintTypesModel>): PaintTypesModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewPaintType$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewPaintType$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaintTypesModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaintTypesService.GetNewPaintTypePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaintTypesModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewPaintType$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewPaintType$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<PaintTypesModel> {
    return this.getNewPaintType$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaintTypesModel>): PaintTypesModel => r.body)
    );
  }

  /** Path part for operation `createPaintType()` */
  static readonly CreatePaintTypePath = '/paint-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPaintType$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPaintType$Plain$Response(
    params?: {
      body?: PaintTypesModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaintType>> {
    const rb = new RequestBuilder(this.rootUrl, PaintTypesService.CreatePaintTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaintType>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPaintType$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPaintType$Plain(
    params?: {
      body?: PaintTypesModel
    },
    context?: HttpContext
  ): Observable<PaintType> {
    return this.createPaintType$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaintType>): PaintType => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPaintType$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPaintType$Json$Response(
    params?: {
      body?: PaintTypesModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaintType>> {
    const rb = new RequestBuilder(this.rootUrl, PaintTypesService.CreatePaintTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaintType>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPaintType$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPaintType$Json(
    params?: {
      body?: PaintTypesModel
    },
    context?: HttpContext
  ): Observable<PaintType> {
    return this.createPaintType$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaintType>): PaintType => r.body)
    );
  }

  /** Path part for operation `isPaintTypeInUse()` */
  static readonly IsPaintTypeInUsePath = '/paint-types/inuse/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isPaintTypeInUse$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPaintTypeInUse$Plain$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PaintTypesService.IsPaintTypeInUsePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isPaintTypeInUse$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPaintTypeInUse$Plain(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.isPaintTypeInUse$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isPaintTypeInUse$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPaintTypeInUse$Json$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PaintTypesService.IsPaintTypeInUsePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isPaintTypeInUse$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPaintTypeInUse$Json(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.isPaintTypeInUse$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getPaintTypesDropdown()` */
  static readonly GetPaintTypesDropdownPath = '/paint-types/list-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaintTypesDropdown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaintTypesDropdown$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, PaintTypesService.GetPaintTypesDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaintTypesDropdown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaintTypesDropdown$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<any> {
    return this.getPaintTypesDropdown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaintTypesDropdown$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaintTypesDropdown$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, PaintTypesService.GetPaintTypesDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaintTypesDropdown$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaintTypesDropdown$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<any> {
    return this.getPaintTypesDropdown$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
