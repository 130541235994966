import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AuthModule, authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { AuthComponent } from './auth/auth.component';
import { AuthErrorComponent } from './auth-error/auth-error.component';
import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApiModule } from 'api';
import { ModulePickerComponent } from './module-picker/module-picker.component';
import { CCPickerComponent } from './cc-picker/cc-picker.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CcResetComponent } from './cc-reset/cc-reset.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    AuthErrorComponent,
    ModulePickerComponent,
    CCPickerComponent,
    CcResetComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,

    MatDialogModule,
    MatButtonModule,
    MatListModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressBarModule,

    ApiModule.forRoot({ rootUrl: environment.apiUrl }),

    AuthModule.forRoot({
      domain: environment.auth.domain,
      clientId: environment.auth.clientId,
      authorizationParams: environment.auth.authorizationParams,
      httpInterceptor: {
        allowedList: [
          `*`
        ]
      }
    })

  ],
  providers: [
    provideHttpClient(withInterceptors([authHttpInterceptorFn])),
    provideAuth0({
      ...environment.auth,
      httpInterceptor: {
        allowedList: [
          `*`
        ]
      },
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
