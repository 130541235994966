/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AttachmentModel } from '../models/attachment-model';
import { DealershipModel } from '../models/dealership-model';
import { EnquiryImageModel } from '../models/enquiry-image-model';
import { EstimateEnquiryModel } from '../models/estimate-enquiry-model';
import { RotateModel } from '../models/rotate-model';

@Injectable({ providedIn: 'root' })
export class EstimateEnquiryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getEnquiry()` */
  static readonly GetEnquiryPath = '/enquiries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnquiry$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnquiry$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateEnquiryModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.GetEnquiryPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateEnquiryModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEnquiry$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnquiry$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateEnquiryModel> {
    return this.getEnquiry$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateEnquiryModel>): EstimateEnquiryModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnquiry$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnquiry$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateEnquiryModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.GetEnquiryPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateEnquiryModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEnquiry$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnquiry$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateEnquiryModel> {
    return this.getEnquiry$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateEnquiryModel>): EstimateEnquiryModel => r.body)
    );
  }

  /** Path part for operation `postEnquiry()` */
  static readonly PostEnquiryPath = '/enquiries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postEnquiry()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEnquiry$Response(
    params?: {
      id?: string;
      body?: EstimateEnquiryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.PostEnquiryPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postEnquiry$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEnquiry(
    params?: {
      id?: string;
      body?: EstimateEnquiryModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.postEnquiry$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `newEnquiry()` */
  static readonly NewEnquiryPath = '/enquiries/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newEnquiry$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  newEnquiry$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.NewEnquiryPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newEnquiry$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  newEnquiry$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.newEnquiry$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newEnquiry$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  newEnquiry$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.NewEnquiryPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newEnquiry$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  newEnquiry$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.newEnquiry$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getDealership()` */
  static readonly GetDealershipPath = '/enquiries/dealership';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDealership$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDealership$Plain$Response(
    params?: {
      id?: string;
      companyId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DealershipModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.GetDealershipPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('companyId', params.companyId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealershipModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDealership$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDealership$Plain(
    params?: {
      id?: string;
      companyId?: string;
    },
    context?: HttpContext
  ): Observable<DealershipModel> {
    return this.getDealership$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DealershipModel>): DealershipModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDealership$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDealership$Json$Response(
    params?: {
      id?: string;
      companyId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DealershipModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.GetDealershipPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('companyId', params.companyId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealershipModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDealership$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDealership$Json(
    params?: {
      id?: string;
      companyId?: string;
    },
    context?: HttpContext
  ): Observable<DealershipModel> {
    return this.getDealership$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DealershipModel>): DealershipModel => r.body)
    );
  }

  /** Path part for operation `getEstimate()` */
  static readonly GetEstimatePath = '/enquiries/estimate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimate$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimate$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.GetEstimatePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimate$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimate$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getEstimate$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimate$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimate$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.GetEstimatePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimate$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimate$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getEstimate$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getEnquirySas()` */
  static readonly GetEnquirySasPath = '/enquiries/getsas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnquirySas$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnquirySas$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.GetEnquirySasPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEnquirySas$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnquirySas$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getEnquirySas$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnquirySas$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnquirySas$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.GetEnquirySasPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEnquirySas$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnquirySas$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getEnquirySas$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `rotate()` */
  static readonly RotatePath = '/enquiries/rotate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rotate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rotate$Response(
    params?: {
      body?: RotateModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.RotatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rotate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rotate(
    params?: {
      body?: RotateModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.rotate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileUploaded()` */
  static readonly FileUploadedPath = '/enquiries/uploaded';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileUploaded()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  fileUploaded$Response(
    params?: {
      id?: string;
      rotate?: number;
      body?: AttachmentModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.FileUploadedPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('rotate', params.rotate, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileUploaded$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  fileUploaded(
    params?: {
      id?: string;
      rotate?: number;
      body?: AttachmentModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.fileUploaded$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getImages()` */
  static readonly GetImagesPath = '/enquiries/images';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImages$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImages$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<EnquiryImageModel>>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.GetImagesPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EnquiryImageModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImages$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImages$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<Array<EnquiryImageModel>> {
    return this.getImages$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EnquiryImageModel>>): Array<EnquiryImageModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImages$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImages$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<EnquiryImageModel>>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.GetImagesPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EnquiryImageModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImages$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImages$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<Array<EnquiryImageModel>> {
    return this.getImages$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EnquiryImageModel>>): Array<EnquiryImageModel> => r.body)
    );
  }

  /** Path part for operation `deleteImage()` */
  static readonly DeleteImagePath = '/enquiries/image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteImage$Response(
    params?: {
      id?: string;
      enquiryId?: string;
      fileName?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EstimateEnquiryService.DeleteImagePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('enquiryId', params.enquiryId, {});
      rb.query('fileName', params.fileName, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteImage(
    params?: {
      id?: string;
      enquiryId?: string;
      fileName?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
