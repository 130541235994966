/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CompanyLogoModel } from '../models/company-logo-model';
import { CompanyOptionsModel } from '../models/company-options-model';
import { EstimateListingConfigModel } from '../models/estimate-listing-config-model';
import { MenuItem } from '../models/menu-item';

@Injectable({ providedIn: 'root' })
export class CompanyOptionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCompanyOptions()` */
  static readonly GetCompanyOptionsPath = '/company-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyOptions$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyOptions$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyOptionsModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyOptionsService.GetCompanyOptionsPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyOptionsModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyOptions$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyOptions$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CompanyOptionsModel> {
    return this.getCompanyOptions$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyOptionsModel>): CompanyOptionsModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyOptions$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyOptions$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyOptionsModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyOptionsService.GetCompanyOptionsPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyOptionsModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyOptions$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyOptions$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CompanyOptionsModel> {
    return this.getCompanyOptions$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyOptionsModel>): CompanyOptionsModel => r.body)
    );
  }

  /** Path part for operation `updateCompanyOptions()` */
  static readonly UpdateCompanyOptionsPath = '/company-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyOptions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyOptions$Response(
    params?: {
      id?: string;
      body?: CompanyOptionsModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyOptionsService.UpdateCompanyOptionsPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyOptions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyOptions(
    params?: {
      id?: string;
      body?: CompanyOptionsModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateCompanyOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateCompanyOptionsForm()` */
  static readonly UpdateCompanyOptionsFormPath = '/company-options/forms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyOptionsForm()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyOptionsForm$Response(
    params?: {
      id?: string;
      body?: CompanyOptionsModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyOptionsService.UpdateCompanyOptionsFormPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyOptionsForm$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyOptionsForm(
    params?: {
      id?: string;
      body?: CompanyOptionsModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateCompanyOptionsForm$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCompanyOptionsMenu()` */
  static readonly GetCompanyOptionsMenuPath = '/company-options/menu';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyOptionsMenu$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyOptionsMenu$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
[key: string]: Array<MenuItem>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyOptionsService.GetCompanyOptionsMenuPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: Array<MenuItem>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyOptionsMenu$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyOptionsMenu$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<{
[key: string]: Array<MenuItem>;
}> {
    return this.getCompanyOptionsMenu$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: Array<MenuItem>;
}>): {
[key: string]: Array<MenuItem>;
} => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyOptionsMenu$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyOptionsMenu$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
[key: string]: Array<MenuItem>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyOptionsService.GetCompanyOptionsMenuPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: Array<MenuItem>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyOptionsMenu$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyOptionsMenu$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<{
[key: string]: Array<MenuItem>;
}> {
    return this.getCompanyOptionsMenu$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: Array<MenuItem>;
}>): {
[key: string]: Array<MenuItem>;
} => r.body)
    );
  }

  /** Path part for operation `updateCompanyOptionsMenu()` */
  static readonly UpdateCompanyOptionsMenuPath = '/company-options/menu';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyOptionsMenu()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyOptionsMenu$Response(
    params?: {
      id?: string;
      mode?: number;
      body?: Array<MenuItem>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyOptionsService.UpdateCompanyOptionsMenuPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('mode', params.mode, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyOptionsMenu$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyOptionsMenu(
    params?: {
      id?: string;
      mode?: number;
      body?: Array<MenuItem>
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateCompanyOptionsMenu$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getEstimateListing()` */
  static readonly GetEstimateListingPath = '/company-options/estimate-listing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimateListing$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateListing$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateListingConfigModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyOptionsService.GetEstimateListingPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateListingConfigModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimateListing$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateListing$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<EstimateListingConfigModel> {
    return this.getEstimateListing$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateListingConfigModel>): EstimateListingConfigModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimateListing$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateListing$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateListingConfigModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyOptionsService.GetEstimateListingPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateListingConfigModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimateListing$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateListing$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<EstimateListingConfigModel> {
    return this.getEstimateListing$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateListingConfigModel>): EstimateListingConfigModel => r.body)
    );
  }

  /** Path part for operation `updateEstimateListing()` */
  static readonly UpdateEstimateListingPath = '/company-options/estimate-listing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEstimateListing()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateEstimateListing$Response(
    params?: {
      body?: EstimateListingConfigModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyOptionsService.UpdateEstimateListingPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateEstimateListing$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateEstimateListing(
    params?: {
      body?: EstimateListingConfigModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateEstimateListing$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `uploadLogo()` */
  static readonly UploadLogoPath = '/company-options/logo-upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadLogo$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  uploadLogo$Plain$Response(
    params?: {
      body?: CompanyLogoModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyOptionsService.UploadLogoPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadLogo$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  uploadLogo$Plain(
    params?: {
      body?: CompanyLogoModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.uploadLogo$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadLogo$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  uploadLogo$Json$Response(
    params?: {
      body?: CompanyLogoModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyOptionsService.UploadLogoPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadLogo$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  uploadLogo$Json(
    params?: {
      body?: CompanyLogoModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.uploadLogo$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getLogo()` */
  static readonly GetLogoPath = '/company-options/logo-get';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogo$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogo$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyOptionsService.GetLogoPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLogo$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogo$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getLogo$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogo$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogo$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyOptionsService.GetLogoPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLogo$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogo$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getLogo$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
