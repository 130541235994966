/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BooleanStringKeyValuePair } from '../models/boolean-string-key-value-pair';
import { CompanyCopySettingModel } from '../models/company-copy-setting-model';
import { CompanyFinancingModel } from '../models/company-financing-model';
import { CompanyInfoModel } from '../models/company-info-model';
import { CompanyModel } from '../models/company-model';
import { CompanyMultipleCategorySettingModel } from '../models/company-multiple-category-setting-model';
import { CompanyPostcodeModel } from '../models/company-postcode-model';
import { CompanyQueryModel } from '../models/company-query-model';
import { CompanySasModel } from '../models/company-sas-model';
import { CompanySummaryModelResultListingModel } from '../models/company-summary-model-result-listing-model';
import { DropdownModelResultListingModel } from '../models/dropdown-model-result-listing-model';
import { LoginEntity } from '../models/login-entity';
import { LoginQuerymodel } from '../models/login-querymodel';
import { StatesModel } from '../models/states-model';

@Injectable({ providedIn: 'root' })
export class CompaniesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCompanies()` */
  static readonly GetCompaniesPath = '/companies/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanies$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCompanies$Plain$Response(
    params?: {
      body?: CompanyQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanySummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompaniesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanySummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanies$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCompanies$Plain(
    params?: {
      body?: CompanyQueryModel
    },
    context?: HttpContext
  ): Observable<CompanySummaryModelResultListingModel> {
    return this.getCompanies$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanySummaryModelResultListingModel>): CompanySummaryModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanies$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCompanies$Json$Response(
    params?: {
      body?: CompanyQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanySummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompaniesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanySummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanies$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCompanies$Json(
    params?: {
      body?: CompanyQueryModel
    },
    context?: HttpContext
  ): Observable<CompanySummaryModelResultListingModel> {
    return this.getCompanies$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanySummaryModelResultListingModel>): CompanySummaryModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getCompaniesDropdown()` */
  static readonly GetCompaniesDropdownPath = '/companies/list-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompaniesDropdown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompaniesDropdown$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompaniesDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompaniesDropdown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompaniesDropdown$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getCompaniesDropdown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompaniesDropdown$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompaniesDropdown$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompaniesDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompaniesDropdown$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompaniesDropdown$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getCompaniesDropdown$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getRepairersDropdown()` */
  static readonly GetRepairersDropdownPath = '/companies/list-select-repairers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRepairersDropdown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRepairersDropdown$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetRepairersDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRepairersDropdown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRepairersDropdown$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getRepairersDropdown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRepairersDropdown$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRepairersDropdown$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetRepairersDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRepairersDropdown$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRepairersDropdown$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getRepairersDropdown$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getCompany()` */
  static readonly GetCompanyPath = '/companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompany$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompany$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CompanyModel> {
    return this.getCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyModel>): CompanyModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompany$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompany$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompany$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompany$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CompanyModel> {
    return this.getCompany$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyModel>): CompanyModel => r.body)
    );
  }

  /** Path part for operation `putCompany()` */
  static readonly PutCompanyPath = '/companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putCompany$Response(
    params?: {
      id?: string;
      body?: CompanyModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.PutCompanyPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putCompany(
    params?: {
      id?: string;
      body?: CompanyModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.putCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postCompany()` */
  static readonly PostCompanyPath = '/companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCompany$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postCompany$Plain$Response(
    params?: {
      body?: CompanyModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.PostCompanyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCompany$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postCompany$Plain(
    params?: {
      body?: CompanyModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.postCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCompany$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postCompany$Json$Response(
    params?: {
      body?: CompanyModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.PostCompanyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCompany$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postCompany$Json(
    params?: {
      body?: CompanyModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.postCompany$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `deleteCompany()` */
  static readonly DeleteCompanyPath = '/companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompany$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.DeleteCompanyPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompany$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CompanyModel> {
    return this.deleteCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyModel>): CompanyModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompany$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompany$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.DeleteCompanyPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompany$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompany$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CompanyModel> {
    return this.deleteCompany$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyModel>): CompanyModel => r.body)
    );
  }

  /** Path part for operation `getNewCompany()` */
  static readonly GetNewCompanyPath = '/companies/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCompany$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetNewCompanyPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCompany$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<CompanyModel> {
    return this.getNewCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyModel>): CompanyModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewCompany$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCompany$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetNewCompanyPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewCompany$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCompany$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<CompanyModel> {
    return this.getNewCompany$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyModel>): CompanyModel => r.body)
    );
  }

  /** Path part for operation `getCompanyName()` */
  static readonly GetCompanyNamePath = '/companies/name';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyName$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyName$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyNamePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyName$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyName$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getCompanyName$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyName$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyName$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyNamePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyName$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyName$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getCompanyName$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getCompanyInfo()` */
  static readonly GetCompanyInfoPath = '/companies/companyinfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyInfo$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyInfo$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyInfoModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyInfoPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyInfoModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyInfo$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyInfo$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<CompanyInfoModel> {
    return this.getCompanyInfo$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyInfoModel>): CompanyInfoModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyInfo$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyInfo$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyInfoModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyInfoPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyInfoModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyInfo$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyInfo$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<CompanyInfoModel> {
    return this.getCompanyInfo$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyInfoModel>): CompanyInfoModel => r.body)
    );
  }

  /** Path part for operation `checkCodeInUse()` */
  static readonly CheckCodeInUsePath = '/companies/codeinuse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkCodeInUse$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkCodeInUse$Plain$Response(
    params?: {
      id?: string;
      code?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.CheckCodeInUsePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkCodeInUse$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkCodeInUse$Plain(
    params?: {
      id?: string;
      code?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.checkCodeInUse$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkCodeInUse$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkCodeInUse$Json$Response(
    params?: {
      id?: string;
      code?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.CheckCodeInUsePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkCodeInUse$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkCodeInUse$Json(
    params?: {
      id?: string;
      code?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.checkCodeInUse$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `updateTheme()` */
  static readonly UpdateThemePath = '/companies/theme';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTheme()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateTheme$Response(
    params?: {
      id?: string;
      theme?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateThemePath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('theme', params.theme, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTheme$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateTheme(
    params?: {
      id?: string;
      theme?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateTheme$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getUploadSas()` */
  static readonly GetUploadSasPath = '/companies/sas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUploadSas$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUploadSas$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanySasModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetUploadSasPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanySasModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUploadSas$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUploadSas$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<CompanySasModel> {
    return this.getUploadSas$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanySasModel>): CompanySasModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUploadSas$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUploadSas$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanySasModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetUploadSasPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanySasModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUploadSas$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUploadSas$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<CompanySasModel> {
    return this.getUploadSas$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanySasModel>): CompanySasModel => r.body)
    );
  }

  /** Path part for operation `getHasXeroConfig()` */
  static readonly GetHasXeroConfigPath = '/companies/HasXeroConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHasXeroConfig$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHasXeroConfig$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetHasXeroConfigPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHasXeroConfig$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHasXeroConfig$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.getHasXeroConfig$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHasXeroConfig$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHasXeroConfig$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetHasXeroConfigPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHasXeroConfig$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHasXeroConfig$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.getHasXeroConfig$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getStates()` */
  static readonly GetStatesPath = '/companies/States';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStates$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStates$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StatesModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetStatesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatesModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStates$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStates$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<StatesModel> {
    return this.getStates$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatesModel>): StatesModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStates$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStates$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StatesModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetStatesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatesModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStates$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStates$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<StatesModel> {
    return this.getStates$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatesModel>): StatesModel => r.body)
    );
  }

  /** Path part for operation `postCopySettings()` */
  static readonly PostCopySettingsPath = '/companies/copy-settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCopySettings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postCopySettings$Response(
    params?: {
      body?: CompanyCopySettingModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.PostCopySettingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCopySettings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postCopySettings(
    params?: {
      body?: CompanyCopySettingModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.postCopySettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `canCopySetting()` */
  static readonly CanCopySettingPath = '/companies/can-copy-settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `canCopySetting$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  canCopySetting$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BooleanStringKeyValuePair>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.CanCopySettingPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanStringKeyValuePair>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `canCopySetting$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  canCopySetting$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<BooleanStringKeyValuePair> {
    return this.canCopySetting$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanStringKeyValuePair>): BooleanStringKeyValuePair => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `canCopySetting$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  canCopySetting$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BooleanStringKeyValuePair>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.CanCopySettingPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanStringKeyValuePair>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `canCopySetting$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  canCopySetting$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<BooleanStringKeyValuePair> {
    return this.canCopySetting$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanStringKeyValuePair>): BooleanStringKeyValuePair => r.body)
    );
  }

  /** Path part for operation `getMultipleCategorySettings()` */
  static readonly GetMultipleCategorySettingsPath = '/companies/multiple-category-settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMultipleCategorySettings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMultipleCategorySettings$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyMultipleCategorySettingModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetMultipleCategorySettingsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyMultipleCategorySettingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMultipleCategorySettings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMultipleCategorySettings$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<CompanyMultipleCategorySettingModel> {
    return this.getMultipleCategorySettings$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyMultipleCategorySettingModel>): CompanyMultipleCategorySettingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMultipleCategorySettings$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMultipleCategorySettings$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyMultipleCategorySettingModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetMultipleCategorySettingsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyMultipleCategorySettingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMultipleCategorySettings$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMultipleCategorySettings$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<CompanyMultipleCategorySettingModel> {
    return this.getMultipleCategorySettings$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyMultipleCategorySettingModel>): CompanyMultipleCategorySettingModel => r.body)
    );
  }

  /** Path part for operation `getPostcode()` */
  static readonly GetPostcodePath = '/companies/postcode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPostcode$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPostcode$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyPostcodeModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetPostcodePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyPostcodeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPostcode$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPostcode$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CompanyPostcodeModel> {
    return this.getPostcode$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyPostcodeModel>): CompanyPostcodeModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPostcode$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPostcode$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyPostcodeModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetPostcodePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyPostcodeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPostcode$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPostcode$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CompanyPostcodeModel> {
    return this.getPostcode$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyPostcodeModel>): CompanyPostcodeModel => r.body)
    );
  }

  /** Path part for operation `updatePostcode()` */
  static readonly UpdatePostcodePath = '/companies/postcode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePostcode()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePostcode$Response(
    params?: {
      body?: CompanyPostcodeModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdatePostcodePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePostcode$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePostcode(
    params?: {
      body?: CompanyPostcodeModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updatePostcode$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getFinancing()` */
  static readonly GetFinancingPath = '/companies/financing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancing$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancing$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyFinancingModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetFinancingPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyFinancingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancing$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancing$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CompanyFinancingModel> {
    return this.getFinancing$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyFinancingModel>): CompanyFinancingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancing$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancing$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CompanyFinancingModel>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetFinancingPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyFinancingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancing$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancing$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CompanyFinancingModel> {
    return this.getFinancing$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyFinancingModel>): CompanyFinancingModel => r.body)
    );
  }

  /** Path part for operation `putFinancing()` */
  static readonly PutFinancingPath = '/companies/financing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putFinancing()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putFinancing$Response(
    params?: {
      id?: string;
      body?: CompanyFinancingModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.PutFinancingPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putFinancing$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putFinancing(
    params?: {
      id?: string;
      body?: CompanyFinancingModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.putFinancing$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getLoginLogs()` */
  static readonly GetLoginLogsPath = '/companies/login-log';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLoginLogs$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLoginLogs$Plain$Response(
    params?: {
      id?: string;
      body?: LoginQuerymodel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<LoginEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetLoginLogsPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LoginEntity>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLoginLogs$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLoginLogs$Plain(
    params?: {
      id?: string;
      body?: LoginQuerymodel
    },
    context?: HttpContext
  ): Observable<Array<LoginEntity>> {
    return this.getLoginLogs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LoginEntity>>): Array<LoginEntity> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLoginLogs$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLoginLogs$Json$Response(
    params?: {
      id?: string;
      body?: LoginQuerymodel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<LoginEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetLoginLogsPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LoginEntity>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLoginLogs$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLoginLogs$Json(
    params?: {
      id?: string;
      body?: LoginQuerymodel
    },
    context?: HttpContext
  ): Observable<Array<LoginEntity>> {
    return this.getLoginLogs$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LoginEntity>>): Array<LoginEntity> => r.body)
    );
  }

}
