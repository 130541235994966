/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateEstimateModel } from '../models/create-estimate-model';
import { EstimateDataModel } from '../models/estimate-data-model';

@Injectable({ providedIn: 'root' })
export class EstimatesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `estimatesCreatePost()` */
  static readonly EstimatesCreatePostPath = '/estimates/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `estimatesCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  estimatesCreatePost$Plain$Response(
    params?: {
      body?: CreateEstimateModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, EstimatesService.EstimatesCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `estimatesCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  estimatesCreatePost$Plain(
    params?: {
      body?: CreateEstimateModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.estimatesCreatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `estimatesCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  estimatesCreatePost$Json$Response(
    params?: {
      body?: CreateEstimateModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, EstimatesService.EstimatesCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `estimatesCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  estimatesCreatePost$Json(
    params?: {
      body?: CreateEstimateModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.estimatesCreatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getEstimateData()` */
  static readonly GetEstimateDataPath = '/estimates/data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimateData$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateData$Plain$Response(
    params?: {
      no?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateDataModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimatesService.GetEstimateDataPath, 'get');
    if (params) {
      rb.query('no', params.no, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateDataModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimateData$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateData$Plain(
    params?: {
      no?: number;
    },
    context?: HttpContext
  ): Observable<EstimateDataModel> {
    return this.getEstimateData$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateDataModel>): EstimateDataModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimateData$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateData$Json$Response(
    params?: {
      no?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateDataModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimatesService.GetEstimateDataPath, 'get');
    if (params) {
      rb.query('no', params.no, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateDataModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimateData$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateData$Json(
    params?: {
      no?: number;
    },
    context?: HttpContext
  ): Observable<EstimateDataModel> {
    return this.getEstimateData$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateDataModel>): EstimateDataModel => r.body)
    );
  }

  /** Path part for operation `getEstimateDataById()` */
  static readonly GetEstimateDataByIdPath = '/estimates/data-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimateDataById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateDataById$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateDataModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimatesService.GetEstimateDataByIdPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateDataModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimateDataById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateDataById$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateDataModel> {
    return this.getEstimateDataById$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateDataModel>): EstimateDataModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimateDataById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateDataById$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateDataModel>> {
    const rb = new RequestBuilder(this.rootUrl, EstimatesService.GetEstimateDataByIdPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateDataModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimateDataById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateDataById$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateDataModel> {
    return this.getEstimateDataById$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateDataModel>): EstimateDataModel => r.body)
    );
  }

}
