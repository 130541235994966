/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MenuTemplateModel } from '../models/menu-template-model';
import { MenuTemplateSummaryModel } from '../models/menu-template-summary-model';

@Injectable({ providedIn: 'root' })
export class MenuTemplatesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMenuTemplate()` */
  static readonly GetMenuTemplatePath = '/menu-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMenuTemplate$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuTemplate$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MenuTemplateModel>> {
    const rb = new RequestBuilder(this.rootUrl, MenuTemplatesService.GetMenuTemplatePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MenuTemplateModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMenuTemplate$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuTemplate$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MenuTemplateModel> {
    return this.getMenuTemplate$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MenuTemplateModel>): MenuTemplateModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMenuTemplate$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuTemplate$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MenuTemplateModel>> {
    const rb = new RequestBuilder(this.rootUrl, MenuTemplatesService.GetMenuTemplatePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MenuTemplateModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMenuTemplate$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuTemplate$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<MenuTemplateModel> {
    return this.getMenuTemplate$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MenuTemplateModel>): MenuTemplateModel => r.body)
    );
  }

  /** Path part for operation `updateMenuTemplate()` */
  static readonly UpdateMenuTemplatePath = '/menu-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMenuTemplate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMenuTemplate$Response(
    params?: {
      id?: string;
      body?: MenuTemplateModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MenuTemplatesService.UpdateMenuTemplatePath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMenuTemplate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMenuTemplate(
    params?: {
      id?: string;
      body?: MenuTemplateModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateMenuTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postMenuTemplate()` */
  static readonly PostMenuTemplatePath = '/menu-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMenuTemplate$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postMenuTemplate$Plain$Response(
    params?: {
      body?: MenuTemplateModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, MenuTemplatesService.PostMenuTemplatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postMenuTemplate$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postMenuTemplate$Plain(
    params?: {
      body?: MenuTemplateModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.postMenuTemplate$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMenuTemplate$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postMenuTemplate$Json$Response(
    params?: {
      body?: MenuTemplateModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, MenuTemplatesService.PostMenuTemplatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postMenuTemplate$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postMenuTemplate$Json(
    params?: {
      body?: MenuTemplateModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.postMenuTemplate$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `deleteMenuTemplate()` */
  static readonly DeleteMenuTemplatePath = '/menu-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMenuTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMenuTemplate$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MenuTemplatesService.DeleteMenuTemplatePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMenuTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMenuTemplate(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteMenuTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getMenuTemplateListing()` */
  static readonly GetMenuTemplateListingPath = '/menu-templates/listing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMenuTemplateListing$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuTemplateListing$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MenuTemplateSummaryModel>>> {
    const rb = new RequestBuilder(this.rootUrl, MenuTemplatesService.GetMenuTemplateListingPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MenuTemplateSummaryModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMenuTemplateListing$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuTemplateListing$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MenuTemplateSummaryModel>> {
    return this.getMenuTemplateListing$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MenuTemplateSummaryModel>>): Array<MenuTemplateSummaryModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMenuTemplateListing$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuTemplateListing$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MenuTemplateSummaryModel>>> {
    const rb = new RequestBuilder(this.rootUrl, MenuTemplatesService.GetMenuTemplateListingPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MenuTemplateSummaryModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMenuTemplateListing$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuTemplateListing$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MenuTemplateSummaryModel>> {
    return this.getMenuTemplateListing$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MenuTemplateSummaryModel>>): Array<MenuTemplateSummaryModel> => r.body)
    );
  }

}
