/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CostCentreSelectModel } from '../models/cost-centre-select-model';
import { DropdownModelResultListingModel } from '../models/dropdown-model-result-listing-model';
import { UserAccessQueryModel } from '../models/user-access-query-model';
import { UserAccessSummaryModelResultListingModel } from '../models/user-access-summary-model-result-listing-model';
import { UserLogQueryModel } from '../models/user-log-query-model';
import { UserLogResultResultListingModel } from '../models/user-log-result-result-listing-model';
import { UserModel } from '../models/user-model';
import { UserRights } from '../models/user-rights';

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getUserRoles()` */
  static readonly GetUserRolesPath = '/Users/user-roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserRoles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserRoles$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetUserRolesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserRoles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserRoles$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getUserRoles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserRoles$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserRoles$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetUserRolesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserRoles$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserRoles$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getUserRoles$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getRights()` */
  static readonly GetRightsPath = '/Users/rights';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRights$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRights$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserRights>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetRightsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserRights>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRights$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRights$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<UserRights> {
    return this.getRights$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserRights>): UserRights => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRights$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRights$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserRights>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetRightsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserRights>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRights$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRights$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<UserRights> {
    return this.getRights$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserRights>): UserRights => r.body)
    );
  }

  /** Path part for operation `clearCostCentre()` */
  static readonly ClearCostCentrePath = '/Users/clear-cc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clearCostCentre()` instead.
   *
   * This method doesn't expect any request body.
   */
  clearCostCentre$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ClearCostCentrePath, 'delete');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clearCostCentre$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clearCostCentre(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.clearCostCentre$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `selectCostCentre()` */
  static readonly SelectCostCentrePath = '/Users/select-cc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `selectCostCentre()` instead.
   *
   * This method doesn't expect any request body.
   */
  selectCostCentre$Response(
    params?: {
      companyId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.SelectCostCentrePath, 'put');
    if (params) {
      rb.query('companyId', params.companyId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `selectCostCentre$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  selectCostCentre(
    params?: {
      companyId?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.selectCostCentre$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `hasCostCentre()` */
  static readonly HasCostCentrePath = '/Users/has-cc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hasCostCentre$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasCostCentre$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.HasCostCentrePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hasCostCentre$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasCostCentre$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.hasCostCentre$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hasCostCentre$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasCostCentre$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.HasCostCentrePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hasCostCentre$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasCostCentre$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.hasCostCentre$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getUsersDropdown()` */
  static readonly GetUsersDropdownPath = '/Users/list-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersDropdown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersDropdown$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetUsersDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsersDropdown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersDropdown$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getUsersDropdown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersDropdown$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersDropdown$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetUsersDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsersDropdown$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersDropdown$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getUsersDropdown$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getAccessSummary()` */
  static readonly GetAccessSummaryPath = '/Users/access-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessSummary$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccessSummary$Plain$Response(
    params?: {
      body?: UserAccessQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserAccessSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetAccessSummaryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserAccessSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccessSummary$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccessSummary$Plain(
    params?: {
      body?: UserAccessQueryModel
    },
    context?: HttpContext
  ): Observable<UserAccessSummaryModelResultListingModel> {
    return this.getAccessSummary$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserAccessSummaryModelResultListingModel>): UserAccessSummaryModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessSummary$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccessSummary$Json$Response(
    params?: {
      body?: UserAccessQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserAccessSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetAccessSummaryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserAccessSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccessSummary$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccessSummary$Json(
    params?: {
      body?: UserAccessQueryModel
    },
    context?: HttpContext
  ): Observable<UserAccessSummaryModelResultListingModel> {
    return this.getAccessSummary$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserAccessSummaryModelResultListingModel>): UserAccessSummaryModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getAllSummary()` */
  static readonly GetAllSummaryPath = '/Users/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSummary$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllSummary$Plain$Response(
    params?: {
      body?: UserAccessQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserAccessSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetAllSummaryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserAccessSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSummary$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllSummary$Plain(
    params?: {
      body?: UserAccessQueryModel
    },
    context?: HttpContext
  ): Observable<UserAccessSummaryModelResultListingModel> {
    return this.getAllSummary$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserAccessSummaryModelResultListingModel>): UserAccessSummaryModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSummary$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllSummary$Json$Response(
    params?: {
      body?: UserAccessQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserAccessSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetAllSummaryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserAccessSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSummary$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllSummary$Json(
    params?: {
      body?: UserAccessQueryModel
    },
    context?: HttpContext
  ): Observable<UserAccessSummaryModelResultListingModel> {
    return this.getAllSummary$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserAccessSummaryModelResultListingModel>): UserAccessSummaryModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getUser()` */
  static readonly GetUserPath = '/Users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetUserPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<UserModel> {
    return this.getUser$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserModel>): UserModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetUserPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<UserModel> {
    return this.getUser$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserModel>): UserModel => r.body)
    );
  }

  /** Path part for operation `updateUser()` */
  static readonly UpdateUserPath = '/Users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUser$Response(
    params?: {
      id?: string;
      body?: UserModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UpdateUserPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUser(
    params?: {
      id?: string;
      body?: UserModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteUser()` */
  static readonly DeleteUserPath = '/Users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.DeleteUserPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<UserModel> {
    return this.deleteUser$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserModel>): UserModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.DeleteUserPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<UserModel> {
    return this.deleteUser$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserModel>): UserModel => r.body)
    );
  }

  /** Path part for operation `getNewUser()` */
  static readonly GetNewUserPath = '/Users/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewUser$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewUser$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetNewUserPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewUser$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewUser$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<UserModel> {
    return this.getNewUser$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserModel>): UserModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewUser$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewUser$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetNewUserPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewUser$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewUser$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<UserModel> {
    return this.getNewUser$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserModel>): UserModel => r.body)
    );
  }

  /** Path part for operation `getRoles()` */
  static readonly GetRolesPath = '/Users/roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetRolesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getRoles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoles$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetRolesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoles$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getRoles$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getUserCostCentres()` */
  static readonly GetUserCostCentresPath = '/Users/cost-centres';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserCostCentres$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCostCentres$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CostCentreSelectModel>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetUserCostCentresPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CostCentreSelectModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserCostCentres$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCostCentres$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<CostCentreSelectModel>> {
    return this.getUserCostCentres$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CostCentreSelectModel>>): Array<CostCentreSelectModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserCostCentres$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCostCentres$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CostCentreSelectModel>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GetUserCostCentresPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CostCentreSelectModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserCostCentres$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCostCentres$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<CostCentreSelectModel>> {
    return this.getUserCostCentres$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CostCentreSelectModel>>): Array<CostCentreSelectModel> => r.body)
    );
  }

  /** Path part for operation `checkUserNameInUse()` */
  static readonly CheckUserNameInUsePath = '/Users/IsUsernameInUse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkUserNameInUse$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkUserNameInUse$Plain$Response(
    params?: {
      userId?: string;
      userName?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.CheckUserNameInUsePath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('userName', params.userName, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkUserNameInUse$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkUserNameInUse$Plain(
    params?: {
      userId?: string;
      userName?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.checkUserNameInUse$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkUserNameInUse$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkUserNameInUse$Json$Response(
    params?: {
      userId?: string;
      userName?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.CheckUserNameInUsePath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('userName', params.userName, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkUserNameInUse$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkUserNameInUse$Json(
    params?: {
      userId?: string;
      userName?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.checkUserNameInUse$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `otpStatus()` */
  static readonly OtpStatusPath = '/Users/otp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `otpStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  otpStatus$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.OtpStatusPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `otpStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  otpStatus$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.otpStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `otpStatus$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  otpStatus$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.OtpStatusPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `otpStatus$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  otpStatus$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.otpStatus$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userLogs()` */
  static readonly UserLogsPath = '/Users/logs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userLogs$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userLogs$Plain$Response(
    params?: {
      body?: UserLogQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserLogResultResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UserLogsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserLogResultResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userLogs$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userLogs$Plain(
    params?: {
      body?: UserLogQueryModel
    },
    context?: HttpContext
  ): Observable<UserLogResultResultListingModel> {
    return this.userLogs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserLogResultResultListingModel>): UserLogResultResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userLogs$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userLogs$Json$Response(
    params?: {
      body?: UserLogQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserLogResultResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UserLogsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserLogResultResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userLogs$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userLogs$Json(
    params?: {
      body?: UserLogQueryModel
    },
    context?: HttpContext
  ): Observable<UserLogResultResultListingModel> {
    return this.userLogs$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserLogResultResultListingModel>): UserLogResultResultListingModel => r.body)
    );
  }

  /** Path part for operation `haveIBeenPwned()` */
  static readonly HaveIBeenPwnedPath = '/Users/hibp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `haveIBeenPwned$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  haveIBeenPwned$Plain$Response(
    params?: {
      body?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.HaveIBeenPwnedPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `haveIBeenPwned$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  haveIBeenPwned$Plain(
    params?: {
      body?: string
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.haveIBeenPwned$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `haveIBeenPwned$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  haveIBeenPwned$Json$Response(
    params?: {
      body?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.HaveIBeenPwnedPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `haveIBeenPwned$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  haveIBeenPwned$Json(
    params?: {
      body?: string
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.haveIBeenPwned$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
