/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CarModelModel } from '../models/car-model-model';
import { CarModelSummaryModelResultListingModel } from '../models/car-model-summary-model-result-listing-model';
import { CarModelsQueryModel } from '../models/car-models-query-model';
import { DropdownModelResultListingModel } from '../models/dropdown-model-result-listing-model';

@Injectable({ providedIn: 'root' })
export class ModelsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCarModels()` */
  static readonly GetCarModelsPath = '/Models/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarModels$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCarModels$Plain$Response(
    params?: {
      body?: CarModelsQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarModelSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.GetCarModelsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarModelSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCarModels$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCarModels$Plain(
    params?: {
      body?: CarModelsQueryModel
    },
    context?: HttpContext
  ): Observable<CarModelSummaryModelResultListingModel> {
    return this.getCarModels$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarModelSummaryModelResultListingModel>): CarModelSummaryModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarModels$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCarModels$Json$Response(
    params?: {
      body?: CarModelsQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarModelSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.GetCarModelsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarModelSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCarModels$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCarModels$Json(
    params?: {
      body?: CarModelsQueryModel
    },
    context?: HttpContext
  ): Observable<CarModelSummaryModelResultListingModel> {
    return this.getCarModels$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarModelSummaryModelResultListingModel>): CarModelSummaryModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getCarModelsByMake()` */
  static readonly GetCarModelsByMakePath = '/Models/bymake-list-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarModelsByMake$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarModelsByMake$Plain$Response(
    params?: {
      makeId?: string;
      isTruck?: boolean;
      isVerified?: boolean;
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.GetCarModelsByMakePath, 'get');
    if (params) {
      rb.query('makeId', params.makeId, {});
      rb.query('isTruck', params.isTruck, {});
      rb.query('isVerified', params.isVerified, {});
      rb.query('estimateId', params.estimateId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCarModelsByMake$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarModelsByMake$Plain(
    params?: {
      makeId?: string;
      isTruck?: boolean;
      isVerified?: boolean;
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getCarModelsByMake$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarModelsByMake$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarModelsByMake$Json$Response(
    params?: {
      makeId?: string;
      isTruck?: boolean;
      isVerified?: boolean;
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.GetCarModelsByMakePath, 'get');
    if (params) {
      rb.query('makeId', params.makeId, {});
      rb.query('isTruck', params.isTruck, {});
      rb.query('isVerified', params.isVerified, {});
      rb.query('estimateId', params.estimateId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCarModelsByMake$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarModelsByMake$Json(
    params?: {
      makeId?: string;
      isTruck?: boolean;
      isVerified?: boolean;
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getCarModelsByMake$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `searchModels()` */
  static readonly SearchModelsPath = '/Models/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchModels$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchModels$Plain$Response(
    params?: {
      make?: string;
      key?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.SearchModelsPath, 'get');
    if (params) {
      rb.query('make', params.make, {});
      rb.query('key', params.key, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchModels$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchModels$Plain(
    params?: {
      make?: string;
      key?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.searchModels$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchModels$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchModels$Json$Response(
    params?: {
      make?: string;
      key?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.SearchModelsPath, 'get');
    if (params) {
      rb.query('make', params.make, {});
      rb.query('key', params.key, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchModels$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchModels$Json(
    params?: {
      make?: string;
      key?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.searchModels$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getCarModel()` */
  static readonly GetCarModelPath = '/Models';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarModel$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarModel$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarModelModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.GetCarModelPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarModelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCarModel$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarModel$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CarModelModel> {
    return this.getCarModel$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarModelModel>): CarModelModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarModel$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarModel$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarModelModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.GetCarModelPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarModelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCarModel$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarModel$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CarModelModel> {
    return this.getCarModel$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarModelModel>): CarModelModel => r.body)
    );
  }

  /** Path part for operation `updateCarModel()` */
  static readonly UpdateCarModelPath = '/Models';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCarModel()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCarModel$Response(
    params?: {
      id?: string;
      body?: CarModelModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.UpdateCarModelPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCarModel$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCarModel(
    params?: {
      id?: string;
      body?: CarModelModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateCarModel$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `insertCarModel()` */
  static readonly InsertCarModelPath = '/Models';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertCarModel$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertCarModel$Plain$Response(
    params?: {
      body?: CarModelModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.InsertCarModelPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insertCarModel$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertCarModel$Plain(
    params?: {
      body?: CarModelModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.insertCarModel$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertCarModel$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertCarModel$Json$Response(
    params?: {
      body?: CarModelModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.InsertCarModelPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insertCarModel$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertCarModel$Json(
    params?: {
      body?: CarModelModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.insertCarModel$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `deleteCarModel()` */
  static readonly DeleteCarModelPath = '/Models';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCarModel$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCarModel$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarModelModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.DeleteCarModelPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarModelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCarModel$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCarModel$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CarModelModel> {
    return this.deleteCarModel$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarModelModel>): CarModelModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCarModel$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCarModel$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarModelModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.DeleteCarModelPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarModelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCarModel$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCarModel$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CarModelModel> {
    return this.deleteCarModel$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarModelModel>): CarModelModel => r.body)
    );
  }

  /** Path part for operation `updateCarModelName()` */
  static readonly UpdateCarModelNamePath = '/Models/update-name';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCarModelName$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCarModelName$Plain$Response(
    params?: {
      id?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarModelModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.UpdateCarModelNamePath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarModelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCarModelName$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCarModelName$Plain(
    params?: {
      id?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<CarModelModel> {
    return this.updateCarModelName$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarModelModel>): CarModelModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCarModelName$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCarModelName$Json$Response(
    params?: {
      id?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarModelModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.UpdateCarModelNamePath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarModelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCarModelName$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCarModelName$Json(
    params?: {
      id?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<CarModelModel> {
    return this.updateCarModelName$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarModelModel>): CarModelModel => r.body)
    );
  }

  /** Path part for operation `updateCarModelActive()` */
  static readonly UpdateCarModelActivePath = '/Models/update-active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCarModelActive$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCarModelActive$Plain$Response(
    params?: {
      id?: string;
      isActive?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarModelModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.UpdateCarModelActivePath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('isActive', params.isActive, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarModelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCarModelActive$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCarModelActive$Plain(
    params?: {
      id?: string;
      isActive?: boolean;
    },
    context?: HttpContext
  ): Observable<CarModelModel> {
    return this.updateCarModelActive$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarModelModel>): CarModelModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCarModelActive$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCarModelActive$Json$Response(
    params?: {
      id?: string;
      isActive?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarModelModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.UpdateCarModelActivePath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('isActive', params.isActive, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarModelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCarModelActive$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCarModelActive$Json(
    params?: {
      id?: string;
      isActive?: boolean;
    },
    context?: HttpContext
  ): Observable<CarModelModel> {
    return this.updateCarModelActive$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarModelModel>): CarModelModel => r.body)
    );
  }

  /** Path part for operation `updateCarModelVerified()` */
  static readonly UpdateCarModelVerifiedPath = '/Models/update-verified';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCarModelVerified$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCarModelVerified$Plain$Response(
    params?: {
      id?: string;
      isVerified?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarModelModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.UpdateCarModelVerifiedPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('isVerified', params.isVerified, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarModelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCarModelVerified$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCarModelVerified$Plain(
    params?: {
      id?: string;
      isVerified?: boolean;
    },
    context?: HttpContext
  ): Observable<CarModelModel> {
    return this.updateCarModelVerified$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarModelModel>): CarModelModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCarModelVerified$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCarModelVerified$Json$Response(
    params?: {
      id?: string;
      isVerified?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarModelModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.UpdateCarModelVerifiedPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('isVerified', params.isVerified, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarModelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCarModelVerified$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCarModelVerified$Json(
    params?: {
      id?: string;
      isVerified?: boolean;
    },
    context?: HttpContext
  ): Observable<CarModelModel> {
    return this.updateCarModelVerified$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarModelModel>): CarModelModel => r.body)
    );
  }

  /** Path part for operation `replaceCarModelName()` */
  static readonly ReplaceCarModelNamePath = '/Models/name';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceCarModelName()` instead.
   *
   * This method doesn't expect any request body.
   */
  replaceCarModelName$Response(
    params?: {
      exname?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.ReplaceCarModelNamePath, 'put');
    if (params) {
      rb.query('exname', params.exname, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `replaceCarModelName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  replaceCarModelName(
    params?: {
      exname?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.replaceCarModelName$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `reassignCarModel()` */
  static readonly ReassignCarModelPath = '/Models/reassign-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reassignCarModel()` instead.
   *
   * This method doesn't expect any request body.
   */
  reassignCarModel$Response(
    params?: {
      originId?: string;
      newId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.ReassignCarModelPath, 'put');
    if (params) {
      rb.query('originId', params.originId, {});
      rb.query('newId', params.newId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reassignCarModel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reassignCarModel(
    params?: {
      originId?: string;
      newId?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.reassignCarModel$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getNewCarModel()` */
  static readonly GetNewCarModelPath = '/Models/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewCarModel$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCarModel$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarModelModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.GetNewCarModelPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarModelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewCarModel$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCarModel$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<CarModelModel> {
    return this.getNewCarModel$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarModelModel>): CarModelModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewCarModel$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCarModel$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarModelModel>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.GetNewCarModelPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarModelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewCarModel$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCarModel$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<CarModelModel> {
    return this.getNewCarModel$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarModelModel>): CarModelModel => r.body)
    );
  }

  /** Path part for operation `isCarModelInUse()` */
  static readonly IsCarModelInUsePath = '/Models/inuse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isCarModelInUse$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  isCarModelInUse$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.IsCarModelInUsePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isCarModelInUse$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isCarModelInUse$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.isCarModelInUse$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isCarModelInUse$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  isCarModelInUse$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, ModelsService.IsCarModelInUsePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isCarModelInUse$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isCarModelInUse$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.isCarModelInUse$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
