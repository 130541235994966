import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UsersService } from 'api';
import { CostCentreSelectModel } from 'projects/api/src/projects';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-cc-picker',
  templateUrl: './cc-picker.component.html',
  styleUrls: ['./cc-picker.component.scss']
})
export class CCPickerComponent implements OnInit {
  public isLoading = true;
  public costCentres: CostCentreSelectModel[] = [];
  public filteredCostCentres: CostCentreSelectModel[] = [];
  public searchControl = new FormControl('');

  constructor(
    public dialogRef: MatDialogRef<CCPickerComponent>,
    private userService: UsersService
  ) { }

  ngOnInit(): void {
    this.userService.getUserCostCentres$Json().subscribe({
      next: costCentres => {
        this.costCentres = costCentres;
        this.filteredCostCentres = costCentres; // Initialize filtered list
        this.isLoading = false;

        // Setup search filter
        this.searchControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterCostCentres(value))
          )
          .subscribe(filtered => {
            this.filteredCostCentres = filtered;
          });
      },
      error: err => {
        alert('Error!!!');
        this.isLoading = false;
      }
    });
  }

  filterCostCentres(searchTerm: string): CostCentreSelectModel[] {
    if (!searchTerm) {
      return this.costCentres;
    }
    const lowerSearchTerm = searchTerm.toLowerCase();
    return this.costCentres.filter(costCentre => 
      costCentre.name?.toLowerCase().includes(lowerSearchTerm) || 
      costCentre.code?.toLowerCase().includes(lowerSearchTerm)
    );
  }

  selectCostCentre(costCentre: CostCentreSelectModel) {
    this.dialogRef.close(costCentre.id); // Close the dialog and return the selected cost centre
  }
}
