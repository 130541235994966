/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AttachmentModel } from '../models/attachment-model';
import { AttachmentPreviewModel } from '../models/attachment-preview-model';
import { DamageAttachmentUpdateModel } from '../models/damage-attachment-update-model';

@Injectable({ providedIn: 'root' })
export class AttachmentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDamageAttachments()` */
  static readonly GetDamageAttachmentsPath = '/attachments/damage/listing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDamageAttachments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDamageAttachments$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, AttachmentsService.GetDamageAttachmentsPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDamageAttachments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDamageAttachments$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getDamageAttachments$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDamageAttachments$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDamageAttachments$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, AttachmentsService.GetDamageAttachmentsPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDamageAttachments$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDamageAttachments$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getDamageAttachments$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `putDamageAttachment()` */
  static readonly PutDamageAttachmentPath = '/attachments/damage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putDamageAttachment()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putDamageAttachment$Response(
    params?: {
      id?: string;
      body?: DamageAttachmentUpdateModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AttachmentsService.PutDamageAttachmentPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putDamageAttachment$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putDamageAttachment(
    params?: {
      id?: string;
      body?: DamageAttachmentUpdateModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.putDamageAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postDamageAttachment()` */
  static readonly PostDamageAttachmentPath = '/attachments/damage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDamageAttachment$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postDamageAttachment$Plain$Response(
    params?: {
      body?: AttachmentModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AttachmentsService.PostDamageAttachmentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postDamageAttachment$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postDamageAttachment$Plain(
    params?: {
      body?: AttachmentModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.postDamageAttachment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDamageAttachment$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postDamageAttachment$Json$Response(
    params?: {
      body?: AttachmentModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AttachmentsService.PostDamageAttachmentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postDamageAttachment$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postDamageAttachment$Json(
    params?: {
      body?: AttachmentModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.postDamageAttachment$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getImagePreview()` */
  static readonly GetImagePreviewPath = '/attachments/image/preview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImagePreview$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImagePreview$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AttachmentPreviewModel>> {
    const rb = new RequestBuilder(this.rootUrl, AttachmentsService.GetImagePreviewPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AttachmentPreviewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImagePreview$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImagePreview$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<AttachmentPreviewModel> {
    return this.getImagePreview$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachmentPreviewModel>): AttachmentPreviewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImagePreview$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImagePreview$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AttachmentPreviewModel>> {
    const rb = new RequestBuilder(this.rootUrl, AttachmentsService.GetImagePreviewPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AttachmentPreviewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImagePreview$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImagePreview$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<AttachmentPreviewModel> {
    return this.getImagePreview$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachmentPreviewModel>): AttachmentPreviewModel => r.body)
    );
  }

  /** Path part for operation `getLicense()` */
  static readonly GetLicensePath = '/attachments/license';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLicense()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicense$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AttachmentsService.GetLicensePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLicense$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicense(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.getLicense$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
