/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { DateRangeModel } from '../models/date-range-model';
import { SeriesDataModel } from '../models/series-data-model';

@Injectable({ providedIn: 'root' })
export class DashboardService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getEstimateDateRange()` */
  static readonly GetEstimateDateRangePath = '/dashboard/estimate-date-range';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimateDateRange$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateDateRange$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DateRangeModel>> {
    const rb = new RequestBuilder(this.rootUrl, DashboardService.GetEstimateDateRangePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DateRangeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimateDateRange$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateDateRange$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<DateRangeModel> {
    return this.getEstimateDateRange$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DateRangeModel>): DateRangeModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimateDateRange$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateDateRange$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DateRangeModel>> {
    const rb = new RequestBuilder(this.rootUrl, DashboardService.GetEstimateDateRangePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DateRangeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimateDateRange$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimateDateRange$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<DateRangeModel> {
    return this.getEstimateDateRange$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DateRangeModel>): DateRangeModel => r.body)
    );
  }

  /** Path part for operation `getMtdCategories()` */
  static readonly GetMtdCategoriesPath = '/dashboard/categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMtdCategories$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMtdCategories$Plain$Response(
    params?: {
      year?: number;
      month?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SeriesDataModel>>> {
    const rb = new RequestBuilder(this.rootUrl, DashboardService.GetMtdCategoriesPath, 'get');
    if (params) {
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SeriesDataModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMtdCategories$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMtdCategories$Plain(
    params?: {
      year?: number;
      month?: number;
    },
    context?: HttpContext
  ): Observable<Array<SeriesDataModel>> {
    return this.getMtdCategories$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SeriesDataModel>>): Array<SeriesDataModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMtdCategories$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMtdCategories$Json$Response(
    params?: {
      year?: number;
      month?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SeriesDataModel>>> {
    const rb = new RequestBuilder(this.rootUrl, DashboardService.GetMtdCategoriesPath, 'get');
    if (params) {
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SeriesDataModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMtdCategories$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMtdCategories$Json(
    params?: {
      year?: number;
      month?: number;
    },
    context?: HttpContext
  ): Observable<Array<SeriesDataModel>> {
    return this.getMtdCategories$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SeriesDataModel>>): Array<SeriesDataModel> => r.body)
    );
  }

  /** Path part for operation `getMtdPerformance()` */
  static readonly GetMtdPerformancePath = '/dashboard/performance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMtdPerformance$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMtdPerformance$Plain$Response(
    params?: {
      year?: number;
      month?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SeriesDataModel>>> {
    const rb = new RequestBuilder(this.rootUrl, DashboardService.GetMtdPerformancePath, 'get');
    if (params) {
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SeriesDataModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMtdPerformance$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMtdPerformance$Plain(
    params?: {
      year?: number;
      month?: number;
    },
    context?: HttpContext
  ): Observable<Array<SeriesDataModel>> {
    return this.getMtdPerformance$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SeriesDataModel>>): Array<SeriesDataModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMtdPerformance$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMtdPerformance$Json$Response(
    params?: {
      year?: number;
      month?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SeriesDataModel>>> {
    const rb = new RequestBuilder(this.rootUrl, DashboardService.GetMtdPerformancePath, 'get');
    if (params) {
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SeriesDataModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMtdPerformance$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMtdPerformance$Json(
    params?: {
      year?: number;
      month?: number;
    },
    context?: HttpContext
  ): Observable<Array<SeriesDataModel>> {
    return this.getMtdPerformance$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SeriesDataModel>>): Array<SeriesDataModel> => r.body)
    );
  }

}
