/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AutolineImport } from '../models/autoline-import';
import { AutolineItemImportSummary } from '../models/autoline-item-import-summary';
import { ImportModel } from '../models/import-model';
import { ImportQueryModel } from '../models/import-query-model';
import { ImportSummaryModelResultListingModel } from '../models/import-summary-model-result-listing-model';

@Injectable({ providedIn: 'root' })
export class AutolineService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAutolineImport()` */
  static readonly GetAutolineImportPath = '/autoline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAutolineImport$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAutolineImport$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ImportModel>> {
    const rb = new RequestBuilder(this.rootUrl, AutolineService.GetAutolineImportPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImportModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAutolineImport$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAutolineImport$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<ImportModel> {
    return this.getAutolineImport$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImportModel>): ImportModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAutolineImport$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAutolineImport$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ImportModel>> {
    const rb = new RequestBuilder(this.rootUrl, AutolineService.GetAutolineImportPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImportModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAutolineImport$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAutolineImport$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<ImportModel> {
    return this.getAutolineImport$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImportModel>): ImportModel => r.body)
    );
  }

  /** Path part for operation `postAutolineImport()` */
  static readonly PostAutolineImportPath = '/autoline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAutolineImport$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  postAutolineImport$Plain$Response(
    params?: {
      filename?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AutolineImport>> {
    const rb = new RequestBuilder(this.rootUrl, AutolineService.PostAutolineImportPath, 'post');
    if (params) {
      rb.query('filename', params.filename, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AutolineImport>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postAutolineImport$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postAutolineImport$Plain(
    params?: {
      filename?: string;
    },
    context?: HttpContext
  ): Observable<AutolineImport> {
    return this.postAutolineImport$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AutolineImport>): AutolineImport => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAutolineImport$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  postAutolineImport$Json$Response(
    params?: {
      filename?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AutolineImport>> {
    const rb = new RequestBuilder(this.rootUrl, AutolineService.PostAutolineImportPath, 'post');
    if (params) {
      rb.query('filename', params.filename, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AutolineImport>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postAutolineImport$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postAutolineImport$Json(
    params?: {
      filename?: string;
    },
    context?: HttpContext
  ): Observable<AutolineImport> {
    return this.postAutolineImport$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AutolineImport>): AutolineImport => r.body)
    );
  }

  /** Path part for operation `getAutolineListing()` */
  static readonly GetAutolineListingPath = '/autoline/listing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAutolineListing$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAutolineListing$Plain$Response(
    params?: {
      body?: ImportQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ImportSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, AutolineService.GetAutolineListingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImportSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAutolineListing$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAutolineListing$Plain(
    params?: {
      body?: ImportQueryModel
    },
    context?: HttpContext
  ): Observable<ImportSummaryModelResultListingModel> {
    return this.getAutolineListing$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImportSummaryModelResultListingModel>): ImportSummaryModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAutolineListing$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAutolineListing$Json$Response(
    params?: {
      body?: ImportQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ImportSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, AutolineService.GetAutolineListingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImportSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAutolineListing$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAutolineListing$Json(
    params?: {
      body?: ImportQueryModel
    },
    context?: HttpContext
  ): Observable<ImportSummaryModelResultListingModel> {
    return this.getAutolineListing$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImportSummaryModelResultListingModel>): ImportSummaryModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getAutolineItems()` */
  static readonly GetAutolineItemsPath = '/autoline/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAutolineItems$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAutolineItems$Plain$Response(
    params?: {
      importId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AutolineItemImportSummary>>> {
    const rb = new RequestBuilder(this.rootUrl, AutolineService.GetAutolineItemsPath, 'get');
    if (params) {
      rb.query('importId', params.importId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AutolineItemImportSummary>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAutolineItems$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAutolineItems$Plain(
    params?: {
      importId?: string;
    },
    context?: HttpContext
  ): Observable<Array<AutolineItemImportSummary>> {
    return this.getAutolineItems$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AutolineItemImportSummary>>): Array<AutolineItemImportSummary> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAutolineItems$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAutolineItems$Json$Response(
    params?: {
      importId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AutolineItemImportSummary>>> {
    const rb = new RequestBuilder(this.rootUrl, AutolineService.GetAutolineItemsPath, 'get');
    if (params) {
      rb.query('importId', params.importId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AutolineItemImportSummary>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAutolineItems$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAutolineItems$Json(
    params?: {
      importId?: string;
    },
    context?: HttpContext
  ): Observable<Array<AutolineItemImportSummary>> {
    return this.getAutolineItems$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AutolineItemImportSummary>>): Array<AutolineItemImportSummary> => r.body)
    );
  }

  /** Path part for operation `getAutolineSas()` */
  static readonly GetAutolineSasPath = '/autoline/sas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAutolineSas$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAutolineSas$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AutolineService.GetAutolineSasPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAutolineSas$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAutolineSas$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getAutolineSas$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAutolineSas$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAutolineSas$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AutolineService.GetAutolineSasPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAutolineSas$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAutolineSas$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getAutolineSas$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `retryItemImport()` */
  static readonly RetryItemImportPath = '/autoline/retry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retryItemImport()` instead.
   *
   * This method doesn't expect any request body.
   */
  retryItemImport$Response(
    params?: {
      importId?: string;
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AutolineService.RetryItemImportPath, 'put');
    if (params) {
      rb.query('importId', params.importId, {});
      rb.query('estimateId', params.estimateId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `retryItemImport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  retryItemImport(
    params?: {
      importId?: string;
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.retryItemImport$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
