import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-module-picker',
  templateUrl: './module-picker.component.html',
  styleUrls: ['./module-picker.component.scss']
})

export class ModulePickerComponent {

  public roles = ['Estimates', 'Admin'];

  constructor(
    public dialogRef: MatDialogRef<ModulePickerComponent>,
  ) {}

  selectModule(role: string) {
    this.dialogRef.close(role); // Close the dialog and return the selected module
  }
}