/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { DropdownModelResultListingModel } from '../models/dropdown-model-result-listing-model';
import { PanelModel } from '../models/panel-model';
import { PanelQueryModel } from '../models/panel-query-model';
import { PanelSearchModel } from '../models/panel-search-model';
import { PanelSummaryModelResultListingModel } from '../models/panel-summary-model-result-listing-model';

@Injectable({ providedIn: 'root' })
export class PanelsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `isPanelInUse()` */
  static readonly IsPanelInUsePath = '/panels/inuse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isPanelInUse$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPanelInUse$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.IsPanelInUsePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isPanelInUse$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPanelInUse$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.isPanelInUse$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isPanelInUse$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPanelInUse$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.IsPanelInUsePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isPanelInUse$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPanelInUse$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.isPanelInUse$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getPanels()` */
  static readonly GetPanelsPath = '/panels/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPanels$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPanels$Plain$Response(
    params?: {
      body?: PanelQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PanelSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetPanelsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PanelSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPanels$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPanels$Plain(
    params?: {
      body?: PanelQueryModel
    },
    context?: HttpContext
  ): Observable<PanelSummaryModelResultListingModel> {
    return this.getPanels$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PanelSummaryModelResultListingModel>): PanelSummaryModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPanels$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPanels$Json$Response(
    params?: {
      body?: PanelQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PanelSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetPanelsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PanelSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPanels$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPanels$Json(
    params?: {
      body?: PanelQueryModel
    },
    context?: HttpContext
  ): Observable<PanelSummaryModelResultListingModel> {
    return this.getPanels$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PanelSummaryModelResultListingModel>): PanelSummaryModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getInteriorPanelsDropdown()` */
  static readonly GetInteriorPanelsDropdownPath = '/panels/list-select-interior';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInteriorPanelsDropdown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInteriorPanelsDropdown$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetInteriorPanelsDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInteriorPanelsDropdown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInteriorPanelsDropdown$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getInteriorPanelsDropdown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInteriorPanelsDropdown$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInteriorPanelsDropdown$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetInteriorPanelsDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInteriorPanelsDropdown$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInteriorPanelsDropdown$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getInteriorPanelsDropdown$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getExteriorPanelsDropdown()` */
  static readonly GetExteriorPanelsDropdownPath = '/panels/list-select-exterior';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExteriorPanelsDropdown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExteriorPanelsDropdown$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetExteriorPanelsDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExteriorPanelsDropdown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExteriorPanelsDropdown$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getExteriorPanelsDropdown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExteriorPanelsDropdown$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExteriorPanelsDropdown$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetExteriorPanelsDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExteriorPanelsDropdown$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExteriorPanelsDropdown$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getExteriorPanelsDropdown$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getInteriorUserPanelsDropdown()` */
  static readonly GetInteriorUserPanelsDropdownPath = '/panels/list-select-userinterior';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInteriorUserPanelsDropdown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInteriorUserPanelsDropdown$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetInteriorUserPanelsDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInteriorUserPanelsDropdown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInteriorUserPanelsDropdown$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getInteriorUserPanelsDropdown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInteriorUserPanelsDropdown$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInteriorUserPanelsDropdown$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetInteriorUserPanelsDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInteriorUserPanelsDropdown$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInteriorUserPanelsDropdown$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getInteriorUserPanelsDropdown$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getExteriorUserPanelsDropdown()` */
  static readonly GetExteriorUserPanelsDropdownPath = '/panels/list-select-userexterior';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExteriorUserPanelsDropdown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExteriorUserPanelsDropdown$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetExteriorUserPanelsDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExteriorUserPanelsDropdown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExteriorUserPanelsDropdown$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getExteriorUserPanelsDropdown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExteriorUserPanelsDropdown$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExteriorUserPanelsDropdown$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetExteriorUserPanelsDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExteriorUserPanelsDropdown$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExteriorUserPanelsDropdown$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getExteriorUserPanelsDropdown$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getUserPanelsDropdown()` */
  static readonly GetUserPanelsDropdownPath = '/panels/list-select-userpanels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserPanelsDropdown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPanelsDropdown$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetUserPanelsDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserPanelsDropdown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPanelsDropdown$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getUserPanelsDropdown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserPanelsDropdown$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPanelsDropdown$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetUserPanelsDropdownPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserPanelsDropdown$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPanelsDropdown$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getUserPanelsDropdown$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getPanelByCode()` */
  static readonly GetPanelByCodePath = '/panels/{type}/code/{code}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPanelByCode$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPanelByCode$Plain$Response(
    params: {
      code: string;
      type: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PanelModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetPanelByCodePath, 'get');
    if (params) {
      rb.path('code', params.code, {});
      rb.path('type', params.type, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PanelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPanelByCode$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPanelByCode$Plain(
    params: {
      code: string;
      type: string;
    },
    context?: HttpContext
  ): Observable<PanelModel> {
    return this.getPanelByCode$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PanelModel>): PanelModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPanelByCode$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPanelByCode$Json$Response(
    params: {
      code: string;
      type: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PanelModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetPanelByCodePath, 'get');
    if (params) {
      rb.path('code', params.code, {});
      rb.path('type', params.type, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PanelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPanelByCode$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPanelByCode$Json(
    params: {
      code: string;
      type: string;
    },
    context?: HttpContext
  ): Observable<PanelModel> {
    return this.getPanelByCode$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PanelModel>): PanelModel => r.body)
    );
  }

  /** Path part for operation `searchPanel()` */
  static readonly SearchPanelPath = '/panels/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchPanel$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchPanel$Plain$Response(
    params?: {
      body?: PanelSearchModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PanelModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.SearchPanelPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PanelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchPanel$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchPanel$Plain(
    params?: {
      body?: PanelSearchModel
    },
    context?: HttpContext
  ): Observable<PanelModel> {
    return this.searchPanel$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PanelModel>): PanelModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchPanel$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchPanel$Json$Response(
    params?: {
      body?: PanelSearchModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PanelModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.SearchPanelPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PanelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchPanel$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchPanel$Json(
    params?: {
      body?: PanelSearchModel
    },
    context?: HttpContext
  ): Observable<PanelModel> {
    return this.searchPanel$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PanelModel>): PanelModel => r.body)
    );
  }

  /** Path part for operation `getPanel()` */
  static readonly GetPanelPath = '/panels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPanel$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPanel$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PanelModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetPanelPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PanelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPanel$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPanel$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<PanelModel> {
    return this.getPanel$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PanelModel>): PanelModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPanel$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPanel$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PanelModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetPanelPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PanelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPanel$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPanel$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<PanelModel> {
    return this.getPanel$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PanelModel>): PanelModel => r.body)
    );
  }

  /** Path part for operation `updatePanel()` */
  static readonly UpdatePanelPath = '/panels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePanel()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePanel$Response(
    params?: {
      id?: string;
      body?: PanelModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.UpdatePanelPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePanel$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePanel(
    params?: {
      id?: string;
      body?: PanelModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updatePanel$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `insertPanel()` */
  static readonly InsertPanelPath = '/panels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertPanel$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertPanel$Plain$Response(
    params?: {
      body?: PanelModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.InsertPanelPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insertPanel$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertPanel$Plain(
    params?: {
      body?: PanelModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.insertPanel$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertPanel$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertPanel$Json$Response(
    params?: {
      body?: PanelModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.InsertPanelPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insertPanel$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertPanel$Json(
    params?: {
      body?: PanelModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.insertPanel$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `deletePanel()` */
  static readonly DeletePanelPath = '/panels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePanel$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePanel$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PanelModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.DeletePanelPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PanelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePanel$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePanel$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<PanelModel> {
    return this.deletePanel$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PanelModel>): PanelModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePanel$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePanel$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PanelModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.DeletePanelPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PanelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePanel$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePanel$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<PanelModel> {
    return this.deletePanel$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PanelModel>): PanelModel => r.body)
    );
  }

  /** Path part for operation `getNewPanel()` */
  static readonly GetNewPanelPath = '/panels/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewPanel$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewPanel$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PanelModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetNewPanelPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PanelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewPanel$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewPanel$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<PanelModel> {
    return this.getNewPanel$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PanelModel>): PanelModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewPanel$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewPanel$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PanelModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetNewPanelPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PanelModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewPanel$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewPanel$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<PanelModel> {
    return this.getNewPanel$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PanelModel>): PanelModel => r.body)
    );
  }

  /** Path part for operation `getVehicleLocations()` */
  static readonly GetVehicleLocationsPath = '/panels/vehicle-locations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleLocations$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleLocations$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetVehicleLocationsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicleLocations$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleLocations$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getVehicleLocations$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleLocations$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleLocations$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.GetVehicleLocationsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicleLocations$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleLocations$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getVehicleLocations$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `isPanelUnique()` */
  static readonly IsPanelUniquePath = '/panels/is-unique';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isPanelUnique$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPanelUnique$Plain$Response(
    params?: {
      id?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.IsPanelUniquePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isPanelUnique$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPanelUnique$Plain(
    params?: {
      id?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.isPanelUnique$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isPanelUnique$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPanelUnique$Json$Response(
    params?: {
      id?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.IsPanelUniquePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isPanelUnique$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPanelUnique$Json(
    params?: {
      id?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.isPanelUnique$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `mergePanels()` */
  static readonly MergePanelsPath = '/panels/merge-panel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mergePanels()` instead.
   *
   * This method doesn't expect any request body.
   */
  mergePanels$Response(
    params?: {
      sourceId?: string;
      destId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.MergePanelsPath, 'put');
    if (params) {
      rb.query('sourceId', params.sourceId, {});
      rb.query('destId', params.destId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mergePanels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mergePanels(
    params?: {
      sourceId?: string;
      destId?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.mergePanels$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `toggleIsFixedCostSublet()` */
  static readonly ToggleIsFixedCostSubletPath = '/panels/toggle-sublet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toggleIsFixedCostSublet()` instead.
   *
   * This method doesn't expect any request body.
   */
  toggleIsFixedCostSublet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PanelsService.ToggleIsFixedCostSubletPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `toggleIsFixedCostSublet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  toggleIsFixedCostSublet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.toggleIsFixedCostSublet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
