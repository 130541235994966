/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CheckInDateQueryModel } from '../models/check-in-date-query-model';
import { CheckInDateSummaryModel } from '../models/check-in-date-summary-model';
import { CheckInDateSummaryModelResultListingModel } from '../models/check-in-date-summary-model-result-listing-model';
import { CheckInFormModel } from '../models/check-in-form-model';
import { CreateCheckInFormModel } from '../models/create-check-in-form-model';
import { FormQueryModel } from '../models/form-query-model';
import { ViewFormcheckinsummaryResultListingModel } from '../models/view-formcheckinsummary-result-listing-model';

@Injectable({ providedIn: 'root' })
export class CheckInFormsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCheckInForm()` */
  static readonly GetCheckInFormPath = '/check-in-forms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCheckInForm$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckInForm$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CheckInFormModel>> {
    const rb = new RequestBuilder(this.rootUrl, CheckInFormsService.GetCheckInFormPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CheckInFormModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCheckInForm$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckInForm$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CheckInFormModel> {
    return this.getCheckInForm$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckInFormModel>): CheckInFormModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCheckInForm$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckInForm$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CheckInFormModel>> {
    const rb = new RequestBuilder(this.rootUrl, CheckInFormsService.GetCheckInFormPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CheckInFormModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCheckInForm$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckInForm$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CheckInFormModel> {
    return this.getCheckInForm$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckInFormModel>): CheckInFormModel => r.body)
    );
  }

  /** Path part for operation `updateCheckInForm()` */
  static readonly UpdateCheckInFormPath = '/check-in-forms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCheckInForm()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCheckInForm$Response(
    params?: {
      id?: string;
      body?: CheckInFormModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CheckInFormsService.UpdateCheckInFormPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCheckInForm$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCheckInForm(
    params?: {
      id?: string;
      body?: CheckInFormModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateCheckInForm$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createCheckInForm()` */
  static readonly CreateCheckInFormPath = '/check-in-forms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCheckInForm$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCheckInForm$Plain$Response(
    params?: {
      isCheckIn?: boolean;
      body?: CreateCheckInFormModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CheckInFormsService.CreateCheckInFormPath, 'post');
    if (params) {
      rb.query('isCheckIn', params.isCheckIn, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCheckInForm$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCheckInForm$Plain(
    params?: {
      isCheckIn?: boolean;
      body?: CreateCheckInFormModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.createCheckInForm$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCheckInForm$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCheckInForm$Json$Response(
    params?: {
      isCheckIn?: boolean;
      body?: CreateCheckInFormModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CheckInFormsService.CreateCheckInFormPath, 'post');
    if (params) {
      rb.query('isCheckIn', params.isCheckIn, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCheckInForm$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCheckInForm$Json(
    params?: {
      isCheckIn?: boolean;
      body?: CreateCheckInFormModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.createCheckInForm$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `updateCheckSheet()` */
  static readonly UpdateCheckSheetPath = '/check-in-forms/check-sheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCheckSheet()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCheckSheet$Response(
    params?: {
      id?: string;
      type?: string;
      body?: CheckInFormModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CheckInFormsService.UpdateCheckSheetPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('type', params.type, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCheckSheet$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCheckSheet(
    params?: {
      id?: string;
      type?: string;
      body?: CheckInFormModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateCheckSheet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCheckInFormListing()` */
  static readonly GetCheckInFormListingPath = '/check-in-forms/listing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCheckInFormListing$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCheckInFormListing$Plain$Response(
    params?: {
      body?: FormQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ViewFormcheckinsummaryResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, CheckInFormsService.GetCheckInFormListingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ViewFormcheckinsummaryResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCheckInFormListing$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCheckInFormListing$Plain(
    params?: {
      body?: FormQueryModel
    },
    context?: HttpContext
  ): Observable<ViewFormcheckinsummaryResultListingModel> {
    return this.getCheckInFormListing$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewFormcheckinsummaryResultListingModel>): ViewFormcheckinsummaryResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCheckInFormListing$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCheckInFormListing$Json$Response(
    params?: {
      body?: FormQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ViewFormcheckinsummaryResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, CheckInFormsService.GetCheckInFormListingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ViewFormcheckinsummaryResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCheckInFormListing$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCheckInFormListing$Json(
    params?: {
      body?: FormQueryModel
    },
    context?: HttpContext
  ): Observable<ViewFormcheckinsummaryResultListingModel> {
    return this.getCheckInFormListing$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViewFormcheckinsummaryResultListingModel>): ViewFormcheckinsummaryResultListingModel => r.body)
    );
  }

  /** Path part for operation `getSchedule()` */
  static readonly GetSchedulePath = '/check-in-forms/schedule';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSchedule$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchedule$Plain$Response(
    params?: {
      period?: number;
      type?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CheckInDateSummaryModel>>> {
    const rb = new RequestBuilder(this.rootUrl, CheckInFormsService.GetSchedulePath, 'post');
    if (params) {
      rb.query('period', params.period, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CheckInDateSummaryModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSchedule$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchedule$Plain(
    params?: {
      period?: number;
      type?: string;
    },
    context?: HttpContext
  ): Observable<Array<CheckInDateSummaryModel>> {
    return this.getSchedule$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CheckInDateSummaryModel>>): Array<CheckInDateSummaryModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSchedule$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchedule$Json$Response(
    params?: {
      period?: number;
      type?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CheckInDateSummaryModel>>> {
    const rb = new RequestBuilder(this.rootUrl, CheckInFormsService.GetSchedulePath, 'post');
    if (params) {
      rb.query('period', params.period, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CheckInDateSummaryModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSchedule$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchedule$Json(
    params?: {
      period?: number;
      type?: string;
    },
    context?: HttpContext
  ): Observable<Array<CheckInDateSummaryModel>> {
    return this.getSchedule$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CheckInDateSummaryModel>>): Array<CheckInDateSummaryModel> => r.body)
    );
  }

  /** Path part for operation `getScheduleHistory()` */
  static readonly GetScheduleHistoryPath = '/check-in-forms/schedule-history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScheduleHistory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getScheduleHistory$Plain$Response(
    params?: {
      type?: string;
      body?: CheckInDateQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CheckInDateSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, CheckInFormsService.GetScheduleHistoryPath, 'post');
    if (params) {
      rb.query('type', params.type, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CheckInDateSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScheduleHistory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getScheduleHistory$Plain(
    params?: {
      type?: string;
      body?: CheckInDateQueryModel
    },
    context?: HttpContext
  ): Observable<CheckInDateSummaryModelResultListingModel> {
    return this.getScheduleHistory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckInDateSummaryModelResultListingModel>): CheckInDateSummaryModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScheduleHistory$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getScheduleHistory$Json$Response(
    params?: {
      type?: string;
      body?: CheckInDateQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CheckInDateSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, CheckInFormsService.GetScheduleHistoryPath, 'post');
    if (params) {
      rb.query('type', params.type, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CheckInDateSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScheduleHistory$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getScheduleHistory$Json(
    params?: {
      type?: string;
      body?: CheckInDateQueryModel
    },
    context?: HttpContext
  ): Observable<CheckInDateSummaryModelResultListingModel> {
    return this.getScheduleHistory$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckInDateSummaryModelResultListingModel>): CheckInDateSummaryModelResultListingModel => r.body)
    );
  }

}
