/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CarGraphicMapping } from '../models/car-graphic-mapping';
import { CarGraphicMappingResultListingModel } from '../models/car-graphic-mapping-result-listing-model';
import { GraphicMappingQueryModel } from '../models/graphic-mapping-query-model';
import { GraphicSideModelGraphicListingModel } from '../models/graphic-side-model-graphic-listing-model';
import { StringGraphicListingModel } from '../models/string-graphic-listing-model';
import { SuggestGraphicModel } from '../models/suggest-graphic-model';

@Injectable({ providedIn: 'root' })
export class GraphicsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMappingList()` */
  static readonly GetMappingListPath = '/Graphics/mapping-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMappingList$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMappingList$Plain$Response(
    params?: {
      body?: GraphicMappingQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarGraphicMappingResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetMappingListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarGraphicMappingResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMappingList$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMappingList$Plain(
    params?: {
      body?: GraphicMappingQueryModel
    },
    context?: HttpContext
  ): Observable<CarGraphicMappingResultListingModel> {
    return this.getMappingList$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarGraphicMappingResultListingModel>): CarGraphicMappingResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMappingList$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMappingList$Json$Response(
    params?: {
      body?: GraphicMappingQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarGraphicMappingResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetMappingListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarGraphicMappingResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMappingList$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMappingList$Json(
    params?: {
      body?: GraphicMappingQueryModel
    },
    context?: HttpContext
  ): Observable<CarGraphicMappingResultListingModel> {
    return this.getMappingList$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarGraphicMappingResultListingModel>): CarGraphicMappingResultListingModel => r.body)
    );
  }

  /** Path part for operation `getMapping()` */
  static readonly GetMappingPath = '/Graphics/mapping';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMapping$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMapping$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarGraphicMapping>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetMappingPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarGraphicMapping>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMapping$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMapping$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CarGraphicMapping> {
    return this.getMapping$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarGraphicMapping>): CarGraphicMapping => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMapping$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMapping$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarGraphicMapping>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetMappingPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarGraphicMapping>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMapping$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMapping$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CarGraphicMapping> {
    return this.getMapping$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarGraphicMapping>): CarGraphicMapping => r.body)
    );
  }

  /** Path part for operation `updateMapping()` */
  static readonly UpdateMappingPath = '/Graphics/mapping';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMapping$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMapping$Plain$Response(
    params?: {
      body?: CarGraphicMapping
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarGraphicMapping>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.UpdateMappingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarGraphicMapping>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMapping$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMapping$Plain(
    params?: {
      body?: CarGraphicMapping
    },
    context?: HttpContext
  ): Observable<CarGraphicMapping> {
    return this.updateMapping$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarGraphicMapping>): CarGraphicMapping => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMapping$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMapping$Json$Response(
    params?: {
      body?: CarGraphicMapping
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CarGraphicMapping>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.UpdateMappingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarGraphicMapping>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMapping$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMapping$Json(
    params?: {
      body?: CarGraphicMapping
    },
    context?: HttpContext
  ): Observable<CarGraphicMapping> {
    return this.updateMapping$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarGraphicMapping>): CarGraphicMapping => r.body)
    );
  }

  /** Path part for operation `deleteMappings()` */
  static readonly DeleteMappingsPath = '/Graphics/delete-mappings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMappings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteMappings$Response(
    params?: {
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.DeleteMappingsPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMappings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteMappings(
    params?: {
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteMappings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `mergeBodyShapes()` */
  static readonly MergeBodyShapesPath = '/Graphics/merge-bodyshape';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mergeBodyShapes()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  mergeBodyShapes$Response(
    params?: {
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.MergeBodyShapesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mergeBodyShapes$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  mergeBodyShapes(
    params?: {
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<void> {
    return this.mergeBodyShapes$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getGraphicList()` */
  static readonly GetGraphicListPath = '/Graphics/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGraphicList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGraphicList$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StringGraphicListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetGraphicListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringGraphicListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGraphicList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGraphicList$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<StringGraphicListingModel> {
    return this.getGraphicList$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StringGraphicListingModel>): StringGraphicListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGraphicList$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGraphicList$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StringGraphicListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetGraphicListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringGraphicListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGraphicList$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGraphicList$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<StringGraphicListingModel> {
    return this.getGraphicList$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<StringGraphicListingModel>): StringGraphicListingModel => r.body)
    );
  }

  /** Path part for operation `getExteriorGraphicList()` */
  static readonly GetExteriorGraphicListPath = '/Graphics/list-exterior';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExteriorGraphicList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExteriorGraphicList$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StringGraphicListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetExteriorGraphicListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringGraphicListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExteriorGraphicList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExteriorGraphicList$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<StringGraphicListingModel> {
    return this.getExteriorGraphicList$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StringGraphicListingModel>): StringGraphicListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExteriorGraphicList$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExteriorGraphicList$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StringGraphicListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetExteriorGraphicListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringGraphicListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExteriorGraphicList$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExteriorGraphicList$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<StringGraphicListingModel> {
    return this.getExteriorGraphicList$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<StringGraphicListingModel>): StringGraphicListingModel => r.body)
    );
  }

  /** Path part for operation `getGraphicSides()` */
  static readonly GetGraphicSidesPath = '/Graphics/list-sides';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGraphicSides$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGraphicSides$Plain$Response(
    params?: {
      graphic?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GraphicSideModelGraphicListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetGraphicSidesPath, 'get');
    if (params) {
      rb.query('graphic', params.graphic, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GraphicSideModelGraphicListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGraphicSides$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGraphicSides$Plain(
    params?: {
      graphic?: string;
    },
    context?: HttpContext
  ): Observable<GraphicSideModelGraphicListingModel> {
    return this.getGraphicSides$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GraphicSideModelGraphicListingModel>): GraphicSideModelGraphicListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGraphicSides$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGraphicSides$Json$Response(
    params?: {
      graphic?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GraphicSideModelGraphicListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetGraphicSidesPath, 'get');
    if (params) {
      rb.query('graphic', params.graphic, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GraphicSideModelGraphicListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGraphicSides$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGraphicSides$Json(
    params?: {
      graphic?: string;
    },
    context?: HttpContext
  ): Observable<GraphicSideModelGraphicListingModel> {
    return this.getGraphicSides$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<GraphicSideModelGraphicListingModel>): GraphicSideModelGraphicListingModel => r.body)
    );
  }

  /** Path part for operation `getGraphicSide()` */
  static readonly GetGraphicSidePath = '/Graphics/side';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGraphicSide$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGraphicSide$Plain$Response(
    params?: {
      graphic?: string;
      side?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetGraphicSidePath, 'get');
    if (params) {
      rb.query('graphic', params.graphic, {});
      rb.query('side', params.side, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGraphicSide$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGraphicSide$Plain(
    params?: {
      graphic?: string;
      side?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getGraphicSide$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGraphicSide$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGraphicSide$Json$Response(
    params?: {
      graphic?: string;
      side?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetGraphicSidePath, 'get');
    if (params) {
      rb.query('graphic', params.graphic, {});
      rb.query('side', params.side, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGraphicSide$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGraphicSide$Json(
    params?: {
      graphic?: string;
      side?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getGraphicSide$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `suggestGraphic()` */
  static readonly SuggestGraphicPath = '/Graphics/suggest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suggestGraphic$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  suggestGraphic$Plain$Response(
    params?: {
      body?: SuggestGraphicModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.SuggestGraphicPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `suggestGraphic$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  suggestGraphic$Plain(
    params?: {
      body?: SuggestGraphicModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.suggestGraphic$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suggestGraphic$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  suggestGraphic$Json$Response(
    params?: {
      body?: SuggestGraphicModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.SuggestGraphicPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `suggestGraphic$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  suggestGraphic$Json(
    params?: {
      body?: SuggestGraphicModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.suggestGraphic$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getLargeGraphic()` */
  static readonly GetLargeGraphicPath = '/Graphics/graphic-image-large';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLargeGraphic$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLargeGraphic$Plain$Response(
    params?: {
      graphic?: string;
      side?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetLargeGraphicPath, 'get');
    if (params) {
      rb.query('graphic', params.graphic, {});
      rb.query('side', params.side, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLargeGraphic$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLargeGraphic$Plain(
    params?: {
      graphic?: string;
      side?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getLargeGraphic$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLargeGraphic$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLargeGraphic$Json$Response(
    params?: {
      graphic?: string;
      side?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetLargeGraphicPath, 'get');
    if (params) {
      rb.query('graphic', params.graphic, {});
      rb.query('side', params.side, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLargeGraphic$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLargeGraphic$Json(
    params?: {
      graphic?: string;
      side?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getLargeGraphic$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getSmallGraphic()` */
  static readonly GetSmallGraphicPath = '/Graphics/graphic-image-small';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSmallGraphic$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmallGraphic$Plain$Response(
    params?: {
      graphic?: string;
      side?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetSmallGraphicPath, 'get');
    if (params) {
      rb.query('graphic', params.graphic, {});
      rb.query('side', params.side, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSmallGraphic$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmallGraphic$Plain(
    params?: {
      graphic?: string;
      side?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getSmallGraphic$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSmallGraphic$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmallGraphic$Json$Response(
    params?: {
      graphic?: string;
      side?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, GraphicsService.GetSmallGraphicPath, 'get');
    if (params) {
      rb.query('graphic', params.graphic, {});
      rb.query('side', params.side, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSmallGraphic$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmallGraphic$Json(
    params?: {
      graphic?: string;
      side?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getSmallGraphic$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
