/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { DropdownModel } from '../models/dropdown-model';
import { SpecialistChargesModel } from '../models/specialist-charges-model';
import { SpecialistChargesQueryModel } from '../models/specialist-charges-query-model';
import { SpecialistChargesSummaryModelResultListingModel } from '../models/specialist-charges-summary-model-result-listing-model';

@Injectable({ providedIn: 'root' })
export class SpecialistChargesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSpecialistChargesDropdown()` */
  static readonly GetSpecialistChargesDropdownPath = '/specialist-charges/list-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSpecialistChargesDropdown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpecialistChargesDropdown$Plain$Response(
    params?: {
      location?: number;
      vehicleSizeId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<DropdownModel>>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialistChargesService.GetSpecialistChargesDropdownPath, 'get');
    if (params) {
      rb.query('location', params.location, {});
      rb.query('vehicleSizeId', params.vehicleSizeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DropdownModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSpecialistChargesDropdown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpecialistChargesDropdown$Plain(
    params?: {
      location?: number;
      vehicleSizeId?: string;
    },
    context?: HttpContext
  ): Observable<Array<DropdownModel>> {
    return this.getSpecialistChargesDropdown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DropdownModel>>): Array<DropdownModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSpecialistChargesDropdown$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpecialistChargesDropdown$Json$Response(
    params?: {
      location?: number;
      vehicleSizeId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<DropdownModel>>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialistChargesService.GetSpecialistChargesDropdownPath, 'get');
    if (params) {
      rb.query('location', params.location, {});
      rb.query('vehicleSizeId', params.vehicleSizeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DropdownModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSpecialistChargesDropdown$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpecialistChargesDropdown$Json(
    params?: {
      location?: number;
      vehicleSizeId?: string;
    },
    context?: HttpContext
  ): Observable<Array<DropdownModel>> {
    return this.getSpecialistChargesDropdown$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DropdownModel>>): Array<DropdownModel> => r.body)
    );
  }

  /** Path part for operation `getSpecialistCharges()` */
  static readonly GetSpecialistChargesPath = '/specialist-charges/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSpecialistCharges$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSpecialistCharges$Plain$Response(
    params?: {
      body?: SpecialistChargesQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SpecialistChargesSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialistChargesService.GetSpecialistChargesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpecialistChargesSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSpecialistCharges$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSpecialistCharges$Plain(
    params?: {
      body?: SpecialistChargesQueryModel
    },
    context?: HttpContext
  ): Observable<SpecialistChargesSummaryModelResultListingModel> {
    return this.getSpecialistCharges$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SpecialistChargesSummaryModelResultListingModel>): SpecialistChargesSummaryModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSpecialistCharges$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSpecialistCharges$Json$Response(
    params?: {
      body?: SpecialistChargesQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SpecialistChargesSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialistChargesService.GetSpecialistChargesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpecialistChargesSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSpecialistCharges$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSpecialistCharges$Json(
    params?: {
      body?: SpecialistChargesQueryModel
    },
    context?: HttpContext
  ): Observable<SpecialistChargesSummaryModelResultListingModel> {
    return this.getSpecialistCharges$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SpecialistChargesSummaryModelResultListingModel>): SpecialistChargesSummaryModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getSpecialistCharge()` */
  static readonly GetSpecialistChargePath = '/specialist-charges';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSpecialistCharge$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpecialistCharge$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SpecialistChargesModel>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialistChargesService.GetSpecialistChargePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpecialistChargesModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSpecialistCharge$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpecialistCharge$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<SpecialistChargesModel> {
    return this.getSpecialistCharge$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SpecialistChargesModel>): SpecialistChargesModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSpecialistCharge$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpecialistCharge$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SpecialistChargesModel>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialistChargesService.GetSpecialistChargePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpecialistChargesModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSpecialistCharge$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSpecialistCharge$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<SpecialistChargesModel> {
    return this.getSpecialistCharge$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SpecialistChargesModel>): SpecialistChargesModel => r.body)
    );
  }

  /** Path part for operation `putSpecialistCharge()` */
  static readonly PutSpecialistChargePath = '/specialist-charges';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putSpecialistCharge()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putSpecialistCharge$Response(
    params?: {
      id?: string;
      body?: SpecialistChargesModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialistChargesService.PutSpecialistChargePath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putSpecialistCharge$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putSpecialistCharge(
    params?: {
      id?: string;
      body?: SpecialistChargesModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.putSpecialistCharge$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postSpecialistCharge()` */
  static readonly PostSpecialistChargePath = '/specialist-charges';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSpecialistCharge$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postSpecialistCharge$Plain$Response(
    params?: {
      body?: SpecialistChargesModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialistChargesService.PostSpecialistChargePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postSpecialistCharge$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postSpecialistCharge$Plain(
    params?: {
      body?: SpecialistChargesModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.postSpecialistCharge$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSpecialistCharge$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postSpecialistCharge$Json$Response(
    params?: {
      body?: SpecialistChargesModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialistChargesService.PostSpecialistChargePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postSpecialistCharge$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postSpecialistCharge$Json(
    params?: {
      body?: SpecialistChargesModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.postSpecialistCharge$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `deleteSpecialistCharge()` */
  static readonly DeleteSpecialistChargePath = '/specialist-charges';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialistCharge$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSpecialistCharge$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SpecialistChargesModel>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialistChargesService.DeleteSpecialistChargePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpecialistChargesModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialistCharge$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSpecialistCharge$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<SpecialistChargesModel> {
    return this.deleteSpecialistCharge$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SpecialistChargesModel>): SpecialistChargesModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialistCharge$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSpecialistCharge$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SpecialistChargesModel>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialistChargesService.DeleteSpecialistChargePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpecialistChargesModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialistCharge$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSpecialistCharge$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<SpecialistChargesModel> {
    return this.deleteSpecialistCharge$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SpecialistChargesModel>): SpecialistChargesModel => r.body)
    );
  }

  /** Path part for operation `getNewSpecialistCharge()` */
  static readonly GetNewSpecialistChargePath = '/specialist-charges/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewSpecialistCharge$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewSpecialistCharge$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SpecialistChargesModel>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialistChargesService.GetNewSpecialistChargePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpecialistChargesModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewSpecialistCharge$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewSpecialistCharge$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<SpecialistChargesModel> {
    return this.getNewSpecialistCharge$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SpecialistChargesModel>): SpecialistChargesModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewSpecialistCharge$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewSpecialistCharge$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SpecialistChargesModel>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialistChargesService.GetNewSpecialistChargePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpecialistChargesModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewSpecialistCharge$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewSpecialistCharge$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<SpecialistChargesModel> {
    return this.getNewSpecialistCharge$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SpecialistChargesModel>): SpecialistChargesModel => r.body)
    );
  }

  /** Path part for operation `checkInUse()` */
  static readonly CheckInUsePath = '/specialist-charges/inuse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkInUse$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkInUse$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialistChargesService.CheckInUsePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkInUse$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkInUse$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.checkInUse$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkInUse$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkInUse$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SpecialistChargesService.CheckInUsePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkInUse$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkInUse$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.checkInUse$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
