/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CourtesyCarListingModel } from '../models/courtesy-car-listing-model';
import { EstOwnerModel } from '../models/est-owner-model';
import { EstVehicleInfoModel } from '../models/est-vehicle-info-model';
import { JobQueryModel } from '../models/job-query-model';
import { JobSummaryModelPagedResultModel } from '../models/job-summary-model-paged-result-model';

@Injectable({ providedIn: 'root' })
export class BodyNetService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getJobs()` */
  static readonly GetJobsPath = '/v1/bodynet/jobs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getJobs$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getJobs$Plain$Response(
    params?: {
      body?: JobQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<JobSummaryModelPagedResultModel>> {
    const rb = new RequestBuilder(this.rootUrl, BodyNetService.GetJobsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobSummaryModelPagedResultModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getJobs$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getJobs$Plain(
    params?: {
      body?: JobQueryModel
    },
    context?: HttpContext
  ): Observable<JobSummaryModelPagedResultModel> {
    return this.getJobs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<JobSummaryModelPagedResultModel>): JobSummaryModelPagedResultModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getJobs$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getJobs$Json$Response(
    params?: {
      body?: JobQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<JobSummaryModelPagedResultModel>> {
    const rb = new RequestBuilder(this.rootUrl, BodyNetService.GetJobsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobSummaryModelPagedResultModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getJobs$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getJobs$Json(
    params?: {
      body?: JobQueryModel
    },
    context?: HttpContext
  ): Observable<JobSummaryModelPagedResultModel> {
    return this.getJobs$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<JobSummaryModelPagedResultModel>): JobSummaryModelPagedResultModel => r.body)
    );
  }

  /** Path part for operation `getCourtesyCars()` */
  static readonly GetCourtesyCarsPath = '/v1/bodynet/courtesy-cars';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCourtesyCars$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCourtesyCars$Plain$Response(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CourtesyCarListingModel>>> {
    const rb = new RequestBuilder(this.rootUrl, BodyNetService.GetCourtesyCarsPath, 'get');
    if (params) {
      rb.query('key', params.key, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CourtesyCarListingModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCourtesyCars$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCourtesyCars$Plain(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<Array<CourtesyCarListingModel>> {
    return this.getCourtesyCars$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CourtesyCarListingModel>>): Array<CourtesyCarListingModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCourtesyCars$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCourtesyCars$Json$Response(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CourtesyCarListingModel>>> {
    const rb = new RequestBuilder(this.rootUrl, BodyNetService.GetCourtesyCarsPath, 'get');
    if (params) {
      rb.query('key', params.key, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CourtesyCarListingModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCourtesyCars$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCourtesyCars$Json(
    params?: {
      key?: string;
    },
    context?: HttpContext
  ): Observable<Array<CourtesyCarListingModel>> {
    return this.getCourtesyCars$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CourtesyCarListingModel>>): Array<CourtesyCarListingModel> => r.body)
    );
  }

  /** Path part for operation `getBnOwner()` */
  static readonly GetBnOwnerPath = '/v1/bodynet/owner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBnOwner$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBnOwner$Plain$Response(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstOwnerModel>> {
    const rb = new RequestBuilder(this.rootUrl, BodyNetService.GetBnOwnerPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstOwnerModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBnOwner$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBnOwner$Plain(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<EstOwnerModel> {
    return this.getBnOwner$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstOwnerModel>): EstOwnerModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBnOwner$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBnOwner$Json$Response(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstOwnerModel>> {
    const rb = new RequestBuilder(this.rootUrl, BodyNetService.GetBnOwnerPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstOwnerModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBnOwner$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBnOwner$Json(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<EstOwnerModel> {
    return this.getBnOwner$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstOwnerModel>): EstOwnerModel => r.body)
    );
  }

  /** Path part for operation `getBnVehicle()` */
  static readonly GetBnVehiclePath = '/v1/bodynet/vehicle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBnVehicle$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBnVehicle$Plain$Response(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstVehicleInfoModel>> {
    const rb = new RequestBuilder(this.rootUrl, BodyNetService.GetBnVehiclePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstVehicleInfoModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBnVehicle$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBnVehicle$Plain(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<EstVehicleInfoModel> {
    return this.getBnVehicle$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstVehicleInfoModel>): EstVehicleInfoModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBnVehicle$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBnVehicle$Json$Response(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstVehicleInfoModel>> {
    const rb = new RequestBuilder(this.rootUrl, BodyNetService.GetBnVehiclePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstVehicleInfoModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBnVehicle$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBnVehicle$Json(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<EstVehicleInfoModel> {
    return this.getBnVehicle$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstVehicleInfoModel>): EstVehicleInfoModel => r.body)
    );
  }

}
