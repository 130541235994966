/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { DerivativeModel } from '../models/derivative-model';
import { DerivativeResultModel } from '../models/derivative-result-model';
import { DerivativeSummaryModelResultListingModel } from '../models/derivative-summary-model-result-listing-model';
import { DerivativesQueryModel } from '../models/derivatives-query-model';
import { DropdownModelResultListingModel } from '../models/dropdown-model-result-listing-model';

@Injectable({ providedIn: 'root' })
export class DerivativesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchDerivatives()` */
  static readonly SearchDerivativesPath = '/Derivatives/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchDerivatives$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchDerivatives$Plain$Response(
    params?: {
      model?: string;
      key?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<DerivativeResultModel>>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.SearchDerivativesPath, 'get');
    if (params) {
      rb.query('model', params.model, {});
      rb.query('key', params.key, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DerivativeResultModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchDerivatives$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchDerivatives$Plain(
    params?: {
      model?: string;
      key?: string;
    },
    context?: HttpContext
  ): Observable<Array<DerivativeResultModel>> {
    return this.searchDerivatives$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DerivativeResultModel>>): Array<DerivativeResultModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchDerivatives$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchDerivatives$Json$Response(
    params?: {
      model?: string;
      key?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<DerivativeResultModel>>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.SearchDerivativesPath, 'get');
    if (params) {
      rb.query('model', params.model, {});
      rb.query('key', params.key, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DerivativeResultModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchDerivatives$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchDerivatives$Json(
    params?: {
      model?: string;
      key?: string;
    },
    context?: HttpContext
  ): Observable<Array<DerivativeResultModel>> {
    return this.searchDerivatives$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DerivativeResultModel>>): Array<DerivativeResultModel> => r.body)
    );
  }

  /** Path part for operation `getDerivatives()` */
  static readonly GetDerivativesPath = '/Derivatives/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDerivatives$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDerivatives$Plain$Response(
    params?: {
      body?: DerivativesQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DerivativeSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.GetDerivativesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DerivativeSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDerivatives$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDerivatives$Plain(
    params?: {
      body?: DerivativesQueryModel
    },
    context?: HttpContext
  ): Observable<DerivativeSummaryModelResultListingModel> {
    return this.getDerivatives$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DerivativeSummaryModelResultListingModel>): DerivativeSummaryModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDerivatives$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDerivatives$Json$Response(
    params?: {
      body?: DerivativesQueryModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DerivativeSummaryModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.GetDerivativesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DerivativeSummaryModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDerivatives$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDerivatives$Json(
    params?: {
      body?: DerivativesQueryModel
    },
    context?: HttpContext
  ): Observable<DerivativeSummaryModelResultListingModel> {
    return this.getDerivatives$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DerivativeSummaryModelResultListingModel>): DerivativeSummaryModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getDerivative()` */
  static readonly GetDerivativePath = '/Derivatives';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDerivative$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDerivative$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DerivativeModel>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.GetDerivativePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DerivativeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDerivative$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDerivative$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<DerivativeModel> {
    return this.getDerivative$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DerivativeModel>): DerivativeModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDerivative$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDerivative$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DerivativeModel>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.GetDerivativePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DerivativeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDerivative$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDerivative$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<DerivativeModel> {
    return this.getDerivative$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DerivativeModel>): DerivativeModel => r.body)
    );
  }

  /** Path part for operation `updateDerivative()` */
  static readonly UpdateDerivativePath = '/Derivatives';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDerivative()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateDerivative$Response(
    params?: {
      id?: string;
      body?: DerivativeModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.UpdateDerivativePath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDerivative$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateDerivative(
    params?: {
      id?: string;
      body?: DerivativeModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateDerivative$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `insertDerivative()` */
  static readonly InsertDerivativePath = '/Derivatives';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertDerivative$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertDerivative$Plain$Response(
    params?: {
      body?: DerivativeModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.InsertDerivativePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insertDerivative$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertDerivative$Plain(
    params?: {
      body?: DerivativeModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.insertDerivative$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertDerivative$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertDerivative$Json$Response(
    params?: {
      body?: DerivativeModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.InsertDerivativePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insertDerivative$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertDerivative$Json(
    params?: {
      body?: DerivativeModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.insertDerivative$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `deleteDerivative()` */
  static readonly DeleteDerivativePath = '/Derivatives';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDerivative$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDerivative$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DerivativeModel>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.DeleteDerivativePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DerivativeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDerivative$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDerivative$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<DerivativeModel> {
    return this.deleteDerivative$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DerivativeModel>): DerivativeModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDerivative$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDerivative$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DerivativeModel>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.DeleteDerivativePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DerivativeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDerivative$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDerivative$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<DerivativeModel> {
    return this.deleteDerivative$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DerivativeModel>): DerivativeModel => r.body)
    );
  }

  /** Path part for operation `getDerivativesByModel()` */
  static readonly GetDerivativesByModelPath = '/Derivatives/bymodel-list-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDerivativesByModel$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDerivativesByModel$Plain$Response(
    params?: {
      modelId?: string;
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.GetDerivativesByModelPath, 'get');
    if (params) {
      rb.query('modelId', params.modelId, {});
      rb.query('estimateId', params.estimateId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDerivativesByModel$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDerivativesByModel$Plain(
    params?: {
      modelId?: string;
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getDerivativesByModel$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDerivativesByModel$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDerivativesByModel$Json$Response(
    params?: {
      modelId?: string;
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.GetDerivativesByModelPath, 'get');
    if (params) {
      rb.query('modelId', params.modelId, {});
      rb.query('estimateId', params.estimateId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDerivativesByModel$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDerivativesByModel$Json(
    params?: {
      modelId?: string;
      estimateId?: string;
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getDerivativesByModel$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getDerivativesByMakeAndModelName()` */
  static readonly GetDerivativesByMakeAndModelNamePath = '/Derivatives/bymakeid-name-list-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDerivativesByMakeAndModelName$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDerivativesByMakeAndModelName$Plain$Response(
    params?: {
      makeId?: string;
      model?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.GetDerivativesByMakeAndModelNamePath, 'get');
    if (params) {
      rb.query('makeId', params.makeId, {});
      rb.query('model', params.model, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDerivativesByMakeAndModelName$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDerivativesByMakeAndModelName$Plain(
    params?: {
      makeId?: string;
      model?: string;
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getDerivativesByMakeAndModelName$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDerivativesByMakeAndModelName$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDerivativesByMakeAndModelName$Json$Response(
    params?: {
      makeId?: string;
      model?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DropdownModelResultListingModel>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.GetDerivativesByMakeAndModelNamePath, 'get');
    if (params) {
      rb.query('makeId', params.makeId, {});
      rb.query('model', params.model, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DropdownModelResultListingModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDerivativesByMakeAndModelName$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDerivativesByMakeAndModelName$Json(
    params?: {
      makeId?: string;
      model?: string;
    },
    context?: HttpContext
  ): Observable<DropdownModelResultListingModel> {
    return this.getDerivativesByMakeAndModelName$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DropdownModelResultListingModel>): DropdownModelResultListingModel => r.body)
    );
  }

  /** Path part for operation `getNewDerivative()` */
  static readonly GetNewDerivativePath = '/Derivatives/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewDerivative$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewDerivative$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DerivativeModel>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.GetNewDerivativePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DerivativeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewDerivative$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewDerivative$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<DerivativeModel> {
    return this.getNewDerivative$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DerivativeModel>): DerivativeModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewDerivative$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewDerivative$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DerivativeModel>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.GetNewDerivativePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DerivativeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewDerivative$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewDerivative$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<DerivativeModel> {
    return this.getNewDerivative$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DerivativeModel>): DerivativeModel => r.body)
    );
  }

  /** Path part for operation `checkDerivativeInUse()` */
  static readonly CheckDerivativeInUsePath = '/Derivatives/inuse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkDerivativeInUse$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkDerivativeInUse$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.CheckDerivativeInUsePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkDerivativeInUse$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkDerivativeInUse$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.checkDerivativeInUse$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkDerivativeInUse$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkDerivativeInUse$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.CheckDerivativeInUsePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkDerivativeInUse$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkDerivativeInUse$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.checkDerivativeInUse$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `updateVerified()` */
  static readonly UpdateVerifiedPath = '/Derivatives/update-verified';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVerified$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateVerified$Plain$Response(
    params?: {
      id?: string;
      isVerified?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DerivativeModel>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.UpdateVerifiedPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('isVerified', params.isVerified, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DerivativeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateVerified$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateVerified$Plain(
    params?: {
      id?: string;
      isVerified?: boolean;
    },
    context?: HttpContext
  ): Observable<DerivativeModel> {
    return this.updateVerified$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DerivativeModel>): DerivativeModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVerified$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateVerified$Json$Response(
    params?: {
      id?: string;
      isVerified?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DerivativeModel>> {
    const rb = new RequestBuilder(this.rootUrl, DerivativesService.UpdateVerifiedPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('isVerified', params.isVerified, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DerivativeModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateVerified$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateVerified$Json(
    params?: {
      id?: string;
      isVerified?: boolean;
    },
    context?: HttpContext
  ): Observable<DerivativeModel> {
    return this.updateVerified$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DerivativeModel>): DerivativeModel => r.body)
    );
  }

}
