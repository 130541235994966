/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AttachmentPreviewModel } from '../models/attachment-preview-model';
import { EstimateCaptureSmsResult } from '../models/estimate-capture-sms-result';
import { EstimateQuotesSmsResult } from '../models/estimate-quotes-sms-result';
import { QuotesDataModel } from '../models/quotes-data-model';
import { UpdateQuoteModel } from '../models/update-quote-model';

@Injectable({ providedIn: 'root' })
export class QuotesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateQuote()` */
  static readonly UpdateQuotePath = '/quotes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateQuote()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateQuote$Response(
    params?: {
      body?: UpdateQuoteModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, QuotesService.UpdateQuotePath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateQuote$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateQuote(
    params?: {
      body?: UpdateQuoteModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateQuote$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getQuotesImages()` */
  static readonly GetQuotesImagesPath = '/quotes/quotes-images';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuotesImages$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuotesImages$Plain$Response(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AttachmentPreviewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, QuotesService.GetQuotesImagesPath, 'get');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AttachmentPreviewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuotesImages$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuotesImages$Plain(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<Array<AttachmentPreviewModel>> {
    return this.getQuotesImages$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AttachmentPreviewModel>>): Array<AttachmentPreviewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuotesImages$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuotesImages$Json$Response(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AttachmentPreviewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, QuotesService.GetQuotesImagesPath, 'get');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AttachmentPreviewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuotesImages$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuotesImages$Json(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<Array<AttachmentPreviewModel>> {
    return this.getQuotesImages$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AttachmentPreviewModel>>): Array<AttachmentPreviewModel> => r.body)
    );
  }

  /** Path part for operation `getQuotesData()` */
  static readonly GetQuotesDataPath = '/quotes/quotes-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuotesData$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuotesData$Plain$Response(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<QuotesDataModel>> {
    const rb = new RequestBuilder(this.rootUrl, QuotesService.GetQuotesDataPath, 'get');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuotesDataModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuotesData$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuotesData$Plain(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<QuotesDataModel> {
    return this.getQuotesData$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<QuotesDataModel>): QuotesDataModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuotesData$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuotesData$Json$Response(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<QuotesDataModel>> {
    const rb = new RequestBuilder(this.rootUrl, QuotesService.GetQuotesDataPath, 'get');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuotesDataModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuotesData$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuotesData$Json(
    params?: {
      code?: string;
    },
    context?: HttpContext
  ): Observable<QuotesDataModel> {
    return this.getQuotesData$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<QuotesDataModel>): QuotesDataModel => r.body)
    );
  }

  /** Path part for operation `getQuotesSmsInfo()` */
  static readonly GetQuotesSmsInfoPath = '/quotes/get-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuotesSmsInfo$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuotesSmsInfo$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateQuotesSmsResult>> {
    const rb = new RequestBuilder(this.rootUrl, QuotesService.GetQuotesSmsInfoPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateQuotesSmsResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuotesSmsInfo$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuotesSmsInfo$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateQuotesSmsResult> {
    return this.getQuotesSmsInfo$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateQuotesSmsResult>): EstimateQuotesSmsResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuotesSmsInfo$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuotesSmsInfo$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateQuotesSmsResult>> {
    const rb = new RequestBuilder(this.rootUrl, QuotesService.GetQuotesSmsInfoPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateQuotesSmsResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuotesSmsInfo$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuotesSmsInfo$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateQuotesSmsResult> {
    return this.getQuotesSmsInfo$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateQuotesSmsResult>): EstimateQuotesSmsResult => r.body)
    );
  }

  /** Path part for operation `sendQuote()` */
  static readonly SendQuotePath = '/quotes/send';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendQuote$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendQuote$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateCaptureSmsResult>> {
    const rb = new RequestBuilder(this.rootUrl, QuotesService.SendQuotePath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateCaptureSmsResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendQuote$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendQuote$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateCaptureSmsResult> {
    return this.sendQuote$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateCaptureSmsResult>): EstimateCaptureSmsResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendQuote$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendQuote$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EstimateCaptureSmsResult>> {
    const rb = new RequestBuilder(this.rootUrl, QuotesService.SendQuotePath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimateCaptureSmsResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendQuote$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendQuote$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<EstimateCaptureSmsResult> {
    return this.sendQuote$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateCaptureSmsResult>): EstimateCaptureSmsResult => r.body)
    );
  }

}
